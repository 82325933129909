import {
  type AssertionConstType,
  type EvmAddress,
  EvmHex,
  type HashTypes
}                                           from "@resources/@types/common/type";
import type {NftGetMetadataQueriesResponse} from "@resources/@types/nft/nft";
import type {Hash}                          from "viem";
import {z}                                  from "zod";


export const GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME = {
  ARBITRUM: "ARBITRUM",
  ZK_SYNC : "ZK_SYNC",
  HAVAH   : "HAVAH",
} as const

export const GetUnsignedTxMutationVariablesChainName = z.nativeEnum(GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME)
export type GetUnsignedTxMutationVariablesChainName = z.infer<typeof GetUnsignedTxMutationVariablesChainName>
export type GetEvmUnsignedTxMutationVariablesChainName = Exclude<GetUnsignedTxMutationVariablesChainName, typeof GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH>


export const SERVICE_NAME = {
  XPER_BRIDGE       : "XPER_BRIDGE",
  PER_BRIDGE        : "PER_BRIDGE",
  ARB_BRIDGE        : "ARB_BRIDGE",
  PERPLAY_NFT_BRIDGE: "PERPLAY_NFT_BRIDGE",
} as const

export type ServiceName = AssertionConstType<typeof SERVICE_NAME>


export type UnsignedTx = {
  from: EvmAddress;
  to: EvmAddress;
  maxFeePerGas: EvmHex;
  maxPriorityFeePerGas: EvmHex;
  value: EvmHex;
  nonce: EvmHex;
  data: Hash;
}

export type UnsignedTxFees = {
  destinationFee: string;
  commissionFee: string;
}


export type BalanceInfo = {
  name: string;
  symbol: string;
  formattedBalanceOf: string;
  originBalanceOf: string;
  decimals: number;
}

export type NftListDataInfo = {
  total: number;
  list: NftGetMetadataQueriesResponse[];
}


export type TransactionPair = {
  idx: number;
  sourceChain: GetUnsignedTxMutationVariablesChainName;
  sourceTransactionHash: HashTypes;
  destinationChain: GetUnsignedTxMutationVariablesChainName;
  destinationTransactionHash: HashTypes;
  serviceName: string;
}

export type CommonGetDestinationTxHashQueryParams = {
  sourceChainTransactionHash: HashTypes;
  refetchInterval?: number;
  retryCount?: number;
}


export type CommonGetDestinationTxHashQueryResponse = null | {
  transactionPair: TransactionPair
}


export type CommonGetRemainApprovedTxQueryParams = {
  sourceChainTransactionHash: Hash;
}

export type CommonGetRemainApprovedTxQueryResponse = {
  destinationFee: string;
  commissionFee: string;
}

export type CommonAddTxPairMutationVariables<chainName = GetUnsignedTxMutationVariablesChainName> = {
  serviceName: ServiceName;
  sourceChain: chainName;
  sourceChainTransactionHash: HashTypes;
  destinationChain: chainName;
}

export type CommonAddTxPairMutationResponse = {
  insertTransactionPairStatus: "Y" | "N" | "D" // Y, N, Duplicate
}

