import {PLATFORM}          from "@resources/@types/common/constant";
import {useCommonActions}  from "@stores/common/store";
import {
  useCallback,
  useEffect,
  useState
} from "react";
import {useResizeDetector} from "react-resize-detector";
import useRootEl           from "./useRootEl";

const useDeviceCheck = () => {
  const DESKTOP_MIN_WIDTH = 1281
  const TABLET_MAX_WIDTH  = DESKTOP_MIN_WIDTH - 1
  const TABLET_MIN_WIDTH  = 769
  const MOBILE_MAX_WIDTH  = TABLET_MIN_WIDTH - 1


  const {
          updateDevice,
          updateIsTouchDevice,
          updateIsSideBarActive
        }         = useCommonActions()
  const {$docRef} = useRootEl()
  const {width}   = useResizeDetector({
    targetRef      : $docRef,
    handleWidth    : true,
    handleHeight   : false,
    refreshMode    : "debounce",
    refreshRate    : 500,
    refreshOptions : {
      leading : true,
      trailing: true,
    },
    observerOptions: {
      box: 'border-box'
    },
    onResize       : useCallback((width?: number, height?: number) => {
      const _hasNavigatorTouch = (navigator.maxTouchPoints || "ontouchstart" in $docRef.current)

      if (!!width) {
        if (width >= DESKTOP_MIN_WIDTH) {
          updateIsTouchDevice(false)
          updateIsSideBarActive(false)
          updateDevice(PLATFORM.DESKTOP)

        } else if (TABLET_MAX_WIDTH >= width && TABLET_MIN_WIDTH <= width) {
          updateIsTouchDevice(true)
          updateDevice(PLATFORM.TABLET)
        } else if (MOBILE_MAX_WIDTH >= width) {
          updateIsTouchDevice(true)
          updateDevice(PLATFORM.MOBILE)
        }
      } else {
        updateIsTouchDevice(true)
      }

    }, [
      navigator.maxTouchPoints,
      document.documentElement,
    ])
  });



  useEffect(() => {
    updateIsTouchDevice(!!((!!width && width > DESKTOP_MIN_WIDTH)
      || (navigator.maxTouchPoints || "ontouchstart" in $docRef.current)))
  }, []);

}

export default useDeviceCheck;
