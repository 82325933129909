import {
  HEADER_TOGGLE_MAIN_PAGE,
  PLATFORM,
  THEME
}                               from "@resources/@types/common/constant";
import {createCommonChainSlice} from "@stores/common/chainSlice";
import {
  storageDecrypt,
  storageEncrypt
}                               from "@utils/Crypto";
import {
  parse,
  stringify
}                               from "@utils/JSON";
import {
  type Draft,
  produce
}                               from "immer";
import type {ValueOf}           from "viem";
import {create}                 from "zustand";
import {
  createJSONStorage,
  devtools,
  persist
}                               from 'zustand/middleware';
import {immer}                  from 'zustand/middleware/immer';
import type {
  CommonMergedStore,
  CommonMergedStoreInitialState,
  CommonStoreInitialState
}                               from "./schema";

type PersistState = {
  state: {
    common: {
      source: CommonMergedStoreInitialState["common"]["source"]
      destination: CommonMergedStoreInitialState["common"]["destination"]
    }
  }
}

const initialState: CommonStoreInitialState = {
  common: {
    headerToggle       : HEADER_TOGGLE_MAIN_PAGE.BRIDGE,
    theme              : THEME.DARK,
    device             : {
      platform     : PLATFORM.DESKTOP,
      isTouchDevice: false,
    },
    isEvmModalOpen     : false,
    isSideBarActive    : false,
    isPageTransitioning: false,
  }
};

const useCommonStore = create<CommonMergedStore>()(
  immer(devtools(
    persist(
      (set, get, store) => ({
        common : {
          ...initialState.common,
          ...createCommonChainSlice(set, get, store).common,
        },
        actions: {
          updateHeaderToggle       : (togglePage) => {
            set(produce((state: Draft<CommonStoreInitialState>) => {
              state.common.headerToggle = togglePage;
            }));
          },
          updateTheme              : (theme) => {
            set(produce((state: Draft<CommonStoreInitialState>) => {
              state.common.theme = theme;
            }));
          },
          updateDevice             : (platform) => {
            set(produce((state: Draft<CommonStoreInitialState>) => {
              state.common.device.platform = platform
            }));
          },
          updateIsTouchDevice      : (isTouchDevice) => {
            set(produce((state: Draft<CommonStoreInitialState>) => {
              state.common.device.isTouchDevice = isTouchDevice
            }));
          },
          updateIsSideBarActive    : (isActive) => {
            set(produce((state: Draft<CommonStoreInitialState>) => {
              state.common.isSideBarActive = isActive;
            }));
          },
          updateIsPageTransitioning: (isTransitioning) => {
            set(produce((state: Draft<CommonStoreInitialState>) => {
              state.common.isPageTransitioning = isTransitioning;
            }));
          },
          updateIsWeb3ModalOpen    : (isOpen) => {
            set(produce((state: Draft<CommonStoreInitialState>) => {
              state.common.isEvmModalOpen = isOpen;
            }));
          },
          ...createCommonChainSlice(set, get, store).actions,
        },
      }),
      {
        name      : 'perflex',
        version   : 1,
        storage   : createJSONStorage(() => ({
          getItem   : (name) => {
            const _str = sessionStorage.getItem(name)
            if (!_str) return null;
            return storageDecrypt(_str)
          },
          setItem   : (name, value) => {
            const _str = storageEncrypt(stringify(value));

            sessionStorage.setItem(name, _str);
          },
          removeItem: (name) => sessionStorage.removeItem(name),
        })),
        partialize: (state) => ({
          common: {
            source     : state.common.source,
            destination: state.common.destination,
          }
        }),
        // // @ts-ignore
        // merge: (persistedState: ValueOf<PersistState> | null, currentState) => {
        //   const {common, actions} = currentState;
        //
        //   if (!!persistedState) {
        //     return {
        //       common : {
        //         ...common,
        //         ...persistedState.common,
        //       },
        //       actions: actions,
        //     };
        //   } else {
        //     return {
        //       ...currentState,
        //     };
        //   }
        // },
      },
    ),
    {
      enabled            : parse(import.meta.env.VITE_CONSOLE_DROP),
      name               : "useCommonStore",
      anonymousActionType: "useCommonStore",
    },
  )));


/** @STATE */
export const useCommonHeaderToggle                       = () => useCommonStore((state) => ({headerToggle: state.common.headerToggle}))
export const useCommonTheme                              = () => useCommonStore((state) => ({theme: state.common.theme}))
export const useCommonDevice                             = () => useCommonStore((state) => ({platform: state.common.device.platform}))
export const useCommonIsTouchDevice                      = () => useCommonStore((state) => ({isTouchDevice: state.common.device.isTouchDevice}))
export const useCommonIsSideBarActive                    = () => useCommonStore((state) => ({isSideBarActive: state.common.isSideBarActive}))
export const useCommonIsPageTransitioning                = () => useCommonStore((state) => ({isPageTransitioning: state.common.isPageTransitioning}))
export const useCommonIsWeb3ModalOpen                    = () => useCommonStore((state) => ({isEvmModalOpen: state.common.isEvmModalOpen}))
export const useCommonSourceChain                        = () => useCommonStore((state) => ({sourceChain: state.common.source.chain}))
export const useCommonDestinationChain                   = () => useCommonStore((state) => ({destinationChain: state.common.destination.chain}))
export const useCommonDestinationChainWalletType         = () => useCommonStore((state) => ({destinationChainWalletType: state.common.destination.walletType}))
export const useCommonDestinationChainOtherWallet        = () => useCommonStore((state) => ({destinationChainOtherWallet: state.common.destination.otherWallet}))
export const useCommonDestinationChainOtherWalletAddress = () => useCommonStore((state) => ({destinationChainOtherWalletAddress: state.common.destination.otherWallet.address}))
/** @ACTIONS */
export const useCommonActions                            = () => useCommonStore((state) => state.actions);
