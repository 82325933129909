export const FirstZeroRegExp = new RegExp(/^0[0-9]/);
export const DecimalRegExp   = new RegExp(/^(?:0|[1-9]\d*)(?:\.\d*)?$/);

export const DotLastRegExp           = new RegExp(/\.$/);
export const DotAfterLastZeroRegExp  = new RegExp(/(\.\d*[1-9])0+$|\.0+$/);
export const AlphabetRegExp          = new RegExp(/^[a-zA-Z]+$/);
export const AlphabetNumberRegExp    = new RegExp(/^[a-zA-Z0-9]+$/g);
export const NonAlphabetNumberRegExp = new RegExp(/[^0-9a-zA-Z]/g);
export const SpecialCharRegExp       = new RegExp(/[!@#$%^&*()_+\-=\[\]{}|;:'",.<>/?]/g);
export const KoreanCharRegExp        = new RegExp(/[ㄱ-ㅣ|가-힣]/g);
export const WhitespaceRegExp        = new RegExp(/\s+/g);