import {
  type ContractId,
  ContractInfo,
  DefineContractInfo,
  DefineEvmContractInfo,
  DefineHavahContractInfo,
  DefineSwapPair,
  type TokenPairs
} from "@resources/@types/common/type";


export const defineContractInfo = (defineInfo: DefineEvmContractInfo | DefineHavahContractInfo) => {
  const {success, data, error} = DefineContractInfo.safeParse(defineInfo)

  if (!success || !data || error) throw new Error("Invalid define contract info")

  const chainHasTestnet = Object.hasOwn(data, "chainTestnet")

  const {contract, devContract, stageContract, ...rest} = data

  // @resources/config/chain 에서 설정된 네트워크 기준으로 변경됨
  // Dev or Stage
  // Testnet
  if (chainHasTestnet && !!data?.chainTestnet) {
    if (import.meta.env.VITE_ENV === "dev") {
      return {
        ...rest,
        contract: devContract,
      }
    } else if (import.meta.env.VITE_ENV === "stage") {
      return {
        ...rest,
        contract: stageContract,
      }
    } else {
      return {
        ...rest,
        contract: "",
      }
    }
  }

  // Mainnet
  return {
    ...rest,
    contract: contract,
  }
}


export const defineSwapPair = (definePairs: DefineSwapPair) => {
  const {
          success: successParsedDefinePairs,
          data   : parsedDefinePairs,
          error  : errorParsedDefinePairs
        } = DefineSwapPair.safeParse(definePairs)

  if (!successParsedDefinePairs || !parsedDefinePairs || errorParsedDefinePairs) {
    console.error(errorParsedDefinePairs)
    throw new Error("Invalid define swap pairs")
  }

  const pairValueList = Object.values(parsedDefinePairs)


  if (
    !pairValueList.length
    || !pairValueList[0] // 기준 토큰
    || !pairValueList[1] // 페어 토큰들
    || !pairValueList[1].length // 페어 토큰 총 개수
  ) throw new Error("Invalid swap pair properties")


  const indexPairCount = pairValueList.reduce((acc, cur) => {
    if (cur.length > 3) throw new Error("Invalid swap pair properties")
    if (cur.length === 3 && cur[2].indexPair) acc++
    return acc
  }, 0)


  if (indexPairCount !== 1) throw new Error("Pairs must be exactly one default pair")

  const pairKeyList = Object.keys(parsedDefinePairs) as ContractId[]

  return pairKeyList.reduce((acc, cur) => {
    if (parsedDefinePairs[cur].length === 3 && parsedDefinePairs[cur][2]!.indexPair) {
      acc["indexPair"] = [parsedDefinePairs[cur][0], parsedDefinePairs[cur][1][0]]
      acc[cur]         = [parsedDefinePairs[cur][0], parsedDefinePairs[cur][1]]
      return acc
    }

    acc[cur] = parsedDefinePairs[cur] as [ContractInfo, ContractInfo[]]

    return acc
  }, {} as TokenPairs)
}
