abstract class _BaseService<T> {
  public api!: T;

  protected initializeApi(_api: T): void {
    this.api = _api as T;
  }
}

export {
  _BaseService as BaseService,
}

