import {UI_DECIMAL}   from "@resources/@types/common/constant";
import {AddressTypes} from "@resources/@types/common/type";
import {
  chains,
  havah
}                     from "@resources/config/contracts";
import {
  AlphabetNumberRegExp,
  DecimalRegExp,
  FirstZeroRegExp,
  KoreanCharRegExp,
  NonAlphabetNumberRegExp,
  SpecialCharRegExp,
  WhitespaceRegExp
}                     from "@utils/RegExp";
import {
  isEvmAddress,
  isIconEoaAddress
}                     from "@utils/Validator";
import {z}            from "zod";

export const BalanceAmount = z.string()
  .transform((amount) => !amount ? "0" : amount)
  .transform((amount) => FirstZeroRegExp.test(amount) ? amount.slice(1, 2) : amount)
  .refine((amount) => DecimalRegExp.test(amount))
  .transform((amount) => {
    if (amount.includes(".")) {
      const [integerPart, fractionalPart = ''] = amount.split('.')

      if (fractionalPart.length > UI_DECIMAL)
        return `${integerPart}.${fractionalPart.slice(0, UI_DECIMAL)}`
    }

    return amount;
  });

export const isValidBalanceAmount = z.string()
  .transform((amount) => {
    const {data, success, error} = BalanceAmount.safeParse(amount)

    return (!(!data || !Number(data) || !success || error))
  });

export const TokenId = z.union([
  z.string(),
  z.number(),
  z.bigint()
])
  .transform((val) => Number(val))
  .refine((val) => !isNaN(val))
  .pipe(
    z.number()
      .int()
      .nonnegative()
      .min(0)
  )
  .refine((val) => Number.isSafeInteger(val));

export const InputAddress = AddressTypes
  .transform((val) => !!val ? val.replace(WhitespaceRegExp, "") : "")
  .transform((val) => AlphabetNumberRegExp.test(val)
    ? val
    : val.replace(NonAlphabetNumberRegExp, "")
  )


export const isValidInputAddressByChain = (chainId: number) => z.string()
  .transform((val, ctx) => {
    if (!isSupportedChain.parse(chainId)) return false

    if (chainId !== havah.id) {
      const _isValid = isEvmAddress(val)
      if (!_isValid) {
        ctx.addIssue({
          code   : z.ZodIssueCode.custom,
          message: `Please enter a valid address that starts with '0x' and is 42 characters long.`,
        });
      }

      return _isValid
    } else if (chainId === havah.id) {
      const _isValid = isIconEoaAddress(val)

      if (!_isValid) {
        ctx.addIssue({
          code   : z.ZodIssueCode.custom,
          message: `Please enter a valid address that starts with 'hx' and is 42 characters long.`,
        });
      }

      return _isValid
    }

    return false
  })

export const isSupportedChain = z.number()
  .transform((val) => {
    const _chains = [...chains, havah]

    return _chains.some(chain => chain.id === val)
  })
