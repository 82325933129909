import {
  ExplorerDetailsPage,
  ExplorerPage,
  FarmPage,
  LiquidPoolPage,
  NFTPage,
  Root,
  SwapPage,
  TokenPage
}                         from "@components/pages";
import {nftLoader}        from "@router/loaders";
import type {QueryClient} from "@tanstack/react-query";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route
}                         from "react-router-dom";


interface PropsType {
  qc: QueryClient
}

const Router = ({qc}: PropsType) =>
  createBrowserRouter(
    createRoutesFromElements([
      <Route
        path="/"
        element={<Root/>}
        // loader={rootLoader(qc)}
      >
        <Route index element={<Navigate to="bridge" replace/>}/>
        {/** @BRIDGE */}
        <Route path="bridge">
          <Route index element={<Navigate to="ft" replace/>}/>
          {/** @NFT */}
          {import.meta.env.VITE_ENV !== "prod"
            // && import.meta.env.VITE_ENV !== "stage"
            && (
              <Route
                path="nft"
                element={<NFTPage/>}
                caseSensitive
                // loader={nftLoader(qc)}
              />
            )}
          {/** @TOKEN */}
          <Route
            path="ft"
            element={<TokenPage/>}
            caseSensitive
            // loader={fungibleTokenLoader(qc)}
          />
          {/** @EXPLORER **/}
          {import.meta.env.VITE_ENV !== "prod"
            // && import.meta.env.VITE_ENV !== "stage"
            && (
              <Route path="explorer">
                <Route
                  index
                  element={<ExplorerPage/>}
                  // loader={explorerLoader(qc)}
                />
                <Route
                  path=":id"
                  element={<ExplorerDetailsPage/>}
                  // loader={explorerDetailsLoader(qc)}
                />
              </Route>
            )}
        </Route>
        {/** @SWAP */}
        {import.meta.env.VITE_ENV !== "prod"
          // && import.meta.env.VITE_ENV !== "stage"
          && (
            <Route path="swap">
              <Route
                index
                element={<SwapPage/>}
                caseSensitive
                // loader={fungibleTokenLoader(qc)}
              />
              <Route
                path="pools"
                element={<LiquidPoolPage/>}
                caseSensitive
                // loader={fungibleTokenLoader(qc)}
              />
              <Route
                path="farm"
                element={<FarmPage/>}
                caseSensitive
                // loader={fungibleTokenLoader(qc)}
              />
            </Route>
          )}
      </Route>,
      <Route path="/*" element={<Navigate to="/" replace/>}/>,
    ]),
  )


export default Router;
