import {DESTINATION_WALLET_TYPE} from "@resources/@types/common/constant";
import {defaultChain}            from "@resources/config/contracts";
import type {ImmerStateCreator}  from "@stores/index";
import {
  type Draft,
  produce
}                                from "immer";
import type {
  CommonChainSlice,
  CommonChainSliceInitialState,
  CommonMergedStore,
  CommonMergedStoreInitialState,
}                                from "./schema";

export const commonChainInitialState: CommonChainSliceInitialState = {
  common: {
    source     : {
      chain: {
        id  : defaultChain.id,
        name: defaultChain.name,
      }
    },
    destination: {
      chain      : {
        id  : 0,
        name: "",
      },
      otherWallet: {
        address : "",
        hasError: false,
        errorMsg: "",
      },
      walletType : DESTINATION_WALLET_TYPE.MY,
    },
  },
}

export const createCommonChainSlice: ImmerStateCreator<CommonMergedStore, CommonChainSlice> = (set, get, store) => ({
  ...commonChainInitialState,
  actions: {
    updateCommonSourceChain                : (sourceChain) => {
      set(produce((state: Draft<CommonMergedStoreInitialState>) => {
        state.common.source.chain = sourceChain;
      }));
    },
    updateCommonDestinationChain           : (destinationChain) => {
      set(produce((state: Draft<CommonMergedStoreInitialState>) => {
        state.common.destination.chain = destinationChain;
      }));
    },
    updateCommonDestinationChainWalletType : (walletType) => {
      set(produce((state: Draft<CommonMergedStoreInitialState>) => {
        state.common.destination.walletType = walletType;
      }));
    },
    updateCommonDestinationChainOtherWallet: (otherWallet) => {
      set(produce((state: Draft<CommonMergedStoreInitialState>) => {
        state.common.destination.otherWallet = otherWallet;
      }));
    },
    resetCommonDestinationChain            : () => {
      set(produce((state: Draft<CommonMergedStoreInitialState>) => {
        state.common.destination.chain = commonChainInitialState.common.destination.chain;
      }));
    },
    resetCommonDestinationChainOtherWallet : () => {
      set(produce((state: Draft<CommonMergedStoreInitialState>) => {
        state.common.destination.otherWallet = commonChainInitialState.common.destination.otherWallet;
      }));
    },
    resetCommonChains                      : () => {
      set(produce((state: Draft<CommonMergedStoreInitialState>) => {
        state.common.source.chain      = commonChainInitialState.common.source.chain;
        state.common.destination.chain = commonChainInitialState.common.destination.chain;
      }));
    }
  }
});
