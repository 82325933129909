import type {
  HavahNftListParams,
  HavahNftListResponse
}                      from "@resources/@types/common/chains/havah/havahDataApi";
import type {
  HavahBalanceParams,
  HavahBalanceResponse,
  HavahGetApprovedNftParams,
  HavahGetApprovedNftResponse,
  HavahTokenAllowanceParams,
  HavahTokenAllowanceResponse,
  HavahTokenBalanceParams,
  HavahTokenBalanceResponse,
  HavahTxReceiptParams,
  HavahTxReceiptResponse
}                      from "@resources/@types/common/chains/havah/havahIconApi";
import type {
  HavahSendTxParams,
  HavahSendTxResponse,
  HavahWalletAccountsResponse,
  HavahWalletApi,
  HavahWalletConnectResponse,
  HavahWalletSignParams,
  HavahWalletSignResponse,
}                      from "@resources/@types/common/chains/havah/havahWalletApi";
import {HavahInstance} from "@services/_Instance";
import {BaseService}   from "@services/_Service";
import {
  HttpProvider,
  IconService,
} from "icon-sdk-js";


export type IHavahService = {
  connect: () => Promise<HavahWalletConnectResponse>;
  accounts: () => Promise<HavahWalletAccountsResponse>;
  signMessage: (message: HavahWalletSignParams) => Promise<HavahWalletSignResponse>;
  sendTx: (transactionData: HavahSendTxParams) => Promise<HavahSendTxResponse>;
  tokenBalance: (params: HavahTokenBalanceParams) => Promise<HavahTokenBalanceResponse>
  balance: (params: HavahBalanceParams) => Promise<HavahBalanceResponse>
  tokenAllowance: (params: HavahTokenAllowanceParams) => Promise<HavahTokenAllowanceResponse>
  getApprovedNft: (params: HavahGetApprovedNftParams) => Promise<HavahGetApprovedNftResponse>
  txReceipt: (params: HavahTxReceiptParams) => Promise<HavahTxReceiptResponse>
  nftList: (params: HavahNftListParams) => Promise<HavahNftListResponse>
}


class HavahService extends BaseService<IHavahService> {
  private havahAxiosInstance: HavahInstance;
  private havahWallet: HavahWalletApi;
  private iconService: IconService;

  constructor() {
    super();

    const _newProvider = new HttpProvider(import.meta.env.VITE_HAVAH_RPC_HTTP_URL)

    this.havahWallet        = window.havah!;
    this.havahAxiosInstance = new HavahInstance();
    this.iconService        = new IconService(_newProvider);

    this.initializeApi({
      connect       : this.connect.bind(this),
      accounts      : this.accounts.bind(this),
      signMessage   : this.signMessage.bind(this),
      sendTx        : this.sendTx.bind(this),
      tokenBalance  : this.tokenBalance.bind(this),
      balance       : this.balance.bind(this),
      tokenAllowance: this.tokenAllowance.bind(this),
      getApprovedNft: this.getApprovedNft.bind(this),
      txReceipt     : this.txReceipt.bind(this),
      nftList       : this.nftList.bind(this),
    })
  }

  /** @WALLET-API **/
  private async connect() {
    return await this.havahWallet.connect()
      .then((res) => res)
      .catch((err) => err)
  }

  private async accounts() {
    return await this.havahWallet.accounts()
      .then((res) => res)
      .catch((err) => err)
  }

  private async signMessage(params: HavahWalletSignParams) {
    return await this.havahWallet.sign(params)
      .then((res) => res)
      .catch((err) => err)
  }

  private async sendTx(params: HavahSendTxParams) {
    return await this.havahWallet.sendTransaction(params)
      .then((res) => res)
      .catch((err) => err)
  }

  /** @ICON-API **/
  private async tokenBalance(params: HavahTokenBalanceParams) {
    const {
            name,
            symbol,
            balanceOf,
            decimals,
          } = params

    try {
      console.log("::: [[[ HAVAH ICON REQUEST ]]] ::: ", params);
      const res = await Promise.all([
        this.iconService.call(name).execute(),
        this.iconService.call(symbol).execute(),
        this.iconService.call(balanceOf).execute(),
        this.iconService.call(decimals).execute(),
      ])
      console.log("::: [[[ HAVAH ICON RESPONSE ]]] ::: ", res);
      return res
    } catch (err) {
      throw err;
    }
  }

  private async balance(params: HavahBalanceParams) {
    const {address} = params

    try {
      console.log("::: [[[ HAVAH ICON REQUEST ]]] ::: ", params);
      const res = await this.iconService.getBalance(address).execute();
      console.log("::: [[[ HAVAH ICON RESPONSE ]]] ::: ", res);
      return res;
    } catch (err) {
      throw err;
    }
  }

  private async tokenAllowance(params: HavahTokenAllowanceParams) {
    const {tokenAllowanceCall} = params

    try {
      console.log("::: [[[ HAVAH ICON REQUEST ]]] ::: ", params);
      const res = await this.iconService.call(tokenAllowanceCall).execute() as HavahTokenAllowanceResponse;
      console.log("::: [[[ HAVAH ICON RESPONSE ]]] ::: ", res);
      return res;
    } catch (err) {
      throw err;
    }
  }

  private async getApprovedNft(params: HavahGetApprovedNftParams) {
    const {getApprovedCall} = params

    try {
      console.log("::: [[[ HAVAH ICON REQUEST ]]] ::: ", params);
      // const res1 = await this.iconService.getScoreApi(havahNftContract.PERPLAY.contract).execute();
      // console.log("::: [[[ HAVAH ICON RESPONSE ]]] ::: ", res1.getList());
      const res = await this.iconService.call(getApprovedCall).execute() as HavahGetApprovedNftResponse;
      console.log("::: [[[ HAVAH ICON RESPONSE ]]] ::: ", res);
      return res;
    } catch (err) {
      throw err;
    }
  }

  private async txReceipt(params: HavahTxReceiptParams) {
    const {txHash} = params
    try {
      console.log("::: [[[ HAVAH ICON REQUEST ]]] ::: ", params);
      const res = await this.iconService.getTransactionResult(txHash as string).execute() as HavahTxReceiptResponse;
      console.log("::: [[[ HAVAH ICON RESPONSE ]]] ::: ", res);
      return res;
    } catch (err) {
      const _err = err as string;

      if (_err.includes("Pending")) {
        return "pending";
      } else if (_err.includes("Executing")) {
        return "executing";
      }

      throw _err;
    }
  }


  /** @DATA-API **/
  private async nftList(params: HavahNftListParams) {
    return await this.havahAxiosInstance.post<HavahNftListResponse, HavahNftListParams>(
      `/nft/collection/token`,
      params
    )
      .then(res => res)
      .catch(err => err)
  }
}

export {
  HavahService
}

