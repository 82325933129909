import {SUCCESS_OR_FAILURE}     from "@resources/@types/common/constant";
import {
  MODAL_TRANSFER_DIRECTION,
  MODAL_TRANSFER_STEP
}                               from "@resources/@types/modal/modal";
import type {
  FungibleTokenMergedStore,
  FungibleTokenMergedStoreInitialState,
  FungibleTokenTransferModalSlice,
  FungibleTokenTransferModalSliceInitialState
}                               from "@stores/fungible-token/schema";
import type {ImmerStateCreator} from "@stores/index";
import {
  type Draft,
  produce
}                               from "immer";


export const ftTransferModalInitialState: FungibleTokenTransferModalSliceInitialState = {
  fungibleToken: {
    transferModal: {
      isOn          : false,
      step          : {
        name     : MODAL_TRANSFER_STEP.INFO_CHECK,
        direction: MODAL_TRANSFER_DIRECTION.NONE,
      },
      progress      : {
        title     : "",
        percentage: 0,
      },
      isTransferring: false,
      result        : {
        type    : SUCCESS_OR_FAILURE.FAILURE,
        txHashes: {
          sourceApproveTxHash: undefined,
          sourceTxHashByChain: undefined,
        },
      },
    },
  }
};

export const createFtTransferModalSlice: ImmerStateCreator<FungibleTokenMergedStore, FungibleTokenTransferModalSlice> = (set, get, store) => ({
  ...ftTransferModalInitialState,
  actions: {
    updateIsFtTransferModalOn              : (isOn) => {
      set(produce((state: Draft<FungibleTokenMergedStoreInitialState>) => {
        state.fungibleToken.transferModal.isOn = isOn;
      }));
    },
    updateFtTransferModalStep              : (step) => {
      set(produce((state: Draft<FungibleTokenMergedStoreInitialState>) => {
        state.fungibleToken.transferModal.step = step;
      }));
    },
    updateIsFtTransferModalTransferring    : (isTransferring) => {
      set(produce((state: Draft<FungibleTokenMergedStoreInitialState>) => {
        state.fungibleToken.transferModal.isTransferring = isTransferring;
      }));
    },
    updateFtTransferModalProgressTitle     : (title) => {
      set(produce((state: Draft<FungibleTokenMergedStoreInitialState>) => {
        state.fungibleToken.transferModal.progress.title = title;
      }));
    },
    updateFtTransferModalProgressPercentage: (percentage) => {
      set(produce((state: Draft<FungibleTokenMergedStoreInitialState>) => {
        state.fungibleToken.transferModal.progress.percentage = percentage;
      }));
    },
    updateFtTransferModalResultType        : (type) => {
      set(produce((state: Draft<FungibleTokenMergedStoreInitialState>) => {
        state.fungibleToken.transferModal.result.type = type;
      }));
    },
    updateFtTransferModalResultTxHash      : (key, txHash) => {
      set(produce((state: Draft<FungibleTokenMergedStoreInitialState>) => {
        state.fungibleToken.transferModal.result.txHashes[key] = txHash;
      }));
    },
    resetFtTransferModal                   : () => {
      set(produce((state: Draft<FungibleTokenMergedStoreInitialState>) => {
        state.fungibleToken.transferModal = ftTransferModalInitialState.fungibleToken.transferModal;
      }));
    }
  }
});

