import {POPUP_STATUS} from "@resources/@types/common/constant";
import type {Toast}   from "@resources/@types/toast/toast";
import {useToastActions} from "@stores/toast/store";
import {
  useCallback,
  useId
}                        from 'react';

interface ReturnType {
  onToast: (props: Omit<Toast, "hash">) => void
}

const useToastControl = (): ReturnType => {
  const {createToast, getToastStatus} = useToastActions();

  const createdHash = useId();

  const onToast = useCallback((props: Omit<Toast, "hash" | "status">) => {
    if (getToastStatus(createdHash) === POPUP_STATUS.ON) return;

    return createToast({hash: createdHash, ...props})
  }, [createToast]);


  return {
    onToast: (props) => onToast(props),
  };
};

export default useToastControl;
