import type {
  HavahNftListQueryParams,
  HavahNftListQueryResponse
}                                     from "@resources/@types/common/chains/havah/havahDataApi";
import type {
  HavahBalanceQueryParams,
  HavahBalanceQueryResponse,
  HavahGetApprovedNftQueryParams,
  HavahGetApprovedNftQueryResponse,
  HavahTokenAllowanceQueryParams,
  HavahTokenAllowanceQueryResponse,
  HavahTokenBalanceParams,
  HavahTokenBalanceQueryParams,
  HavahTokenBalanceQueryResponse,
  HavahTxReceiptQueryParams,
  HavahTxReceiptQueryResponse,
  HavahTxReceiptResponse
}                                     from "@resources/@types/common/chains/havah/havahIconApi";
import {
  type HavahConnectMutationResponse,
  type HavahConnectQueryResponse,
  type HavahSendTxMutationResponse,
  type HavahSendTxMutationVariables,
  type HavahWalletAccountsQueryResponse,
  type HavahWalletSignMutationResponse,
  type HavahWalletSignMutationVariables,
}                                     from "@resources/@types/common/chains/havah/havahWalletApi";
import type {
  HavahAddress,
  ParamMutationOptions,
  ParamQueryOptions
}                                     from "@resources/@types/common/type";
import {havahNftContract} from "@resources/config/contracts";
import {HavahService}                 from "@services/havah/HavahService";
import {
  type MutationOptions,
  queryOptions
}                                     from "@tanstack/react-query";
import {iconToHex}        from "@utils/Format";
import {createRecursiveLongPollingFn} from "@utils/Utils";
import {
  Call,
  CallBuilder
}                                     from "icon-sdk-js";
import isEmpty            from "lodash-es/isEmpty";


const HAVAH_QUERY_KEYS = {
  ALL             : ['havah'] as const,
  LISTS           : () => [...HAVAH_QUERY_KEYS.ALL, "lists"] as const,
  DETAILS         : () => [...HAVAH_QUERY_KEYS.ALL, "details"] as const,
  CONNECT         : () => [...HAVAH_QUERY_KEYS.DETAILS(), "connect"] as const,
  ACCOUNTS        : () => [...HAVAH_QUERY_KEYS.DETAILS(), "accounts"] as const,
  TOKEN_BALANCE   : (
    params: HavahTokenBalanceQueryParams,
  ) => [
    ...HAVAH_QUERY_KEYS.DETAILS(), "tokenBalance",
    params?.address,
    params?.tokenContract,
    {scopeKey: "tokenBalance"},
  ] as const,
  BALANCE         : (
    params: HavahBalanceQueryParams,
  ) => [
    ...HAVAH_QUERY_KEYS.DETAILS(), "nativeBalance",
    params.address,
    {scopeKey: "nativeBalance"},
  ] as const,
  TOKEN_ALLOWANCE : (
    params: HavahTokenAllowanceQueryParams,
  ) => [
    ...HAVAH_QUERY_KEYS.DETAILS(), "tokenAllowance",
    params.address,
    params.tokenContract,
    params.spenderContract,
  ] as const,
  GET_APPROVED_NFT: (
    params: HavahGetApprovedNftQueryParams,
  ) => [
    ...HAVAH_QUERY_KEYS.DETAILS(), "getApprovedNft",
    params.address,
    params.tokenId,
  ] as const,
  TX_RECEIPT      : (
    params: HavahTxReceiptQueryParams,
  ) => [
    ...HAVAH_QUERY_KEYS.DETAILS(), "receipt",
    params.address,
    params.txHash,
  ] as const,
  NFT_LIST        : (
    params: HavahNftListQueryParams,
  ) => [
    ...HAVAH_QUERY_KEYS.LISTS(), "nftList",
    {...params},
    {scopeKey: "nftList"},
  ] as const,
} as const;


/** @Query */
export const havahQueryOptions = {
  connect       : (options?: ParamQueryOptions) => queryOptions<HavahConnectQueryResponse>({
    ...options,
    queryKey: HAVAH_QUERY_KEYS.CONNECT(),
  }),
  accounts      : (options?: ParamQueryOptions) => queryOptions<HavahWalletAccountsQueryResponse>({
    ...options,
    queryKey: HAVAH_QUERY_KEYS.ACCOUNTS(),
  }),
  tokenBalance  : ({address, tokenContract}: HavahTokenBalanceQueryParams, options?: ParamQueryOptions) => queryOptions<HavahTokenBalanceQueryResponse>({
    ...options,
    queryKey: HAVAH_QUERY_KEYS.TOKEN_BALANCE({address, tokenContract}),
    queryFn : async () => {
      if (!address || !tokenContract) throw new Error("Invalid params")

      const initialParams: Record<keyof HavahTokenBalanceParams, Call | undefined> = {
        name     : undefined,
        symbol   : undefined,
        balanceOf: undefined,
        decimals : undefined,
      }

      const callMethodList = Object.keys(initialParams) as (keyof HavahTokenBalanceParams)[];

      const params = callMethodList.reduce((acc, method) => {
        if (method === "balanceOf") {
          acc[method] = new CallBuilder()
            .from(address)
            .to(tokenContract)
            .method(method)
            .params({_owner: address})
            .build();
        } else {
          acc[method] = new CallBuilder()
            .from(address)
            .to(tokenContract)
            .method(method)
            .build();
        }

        return acc;
      }, initialParams as HavahTokenBalanceParams);

      const [name, symbol, balanceOf, decimals] = await new HavahService().api.tokenBalance(params)

      console.log("::: [ HAVAH QUERY - TOKEN BALANCE ] ::: - response", {
        name,
        symbol,
        balanceOf,
        decimals,
      });

      return {
        name,
        symbol,
        balanceOf,
        decimals,
      }
    },
  }),
  balance       : ({address}: HavahBalanceQueryParams, options?: ParamQueryOptions) => queryOptions<HavahBalanceQueryResponse>({
    ...options,
    queryKey: HAVAH_QUERY_KEYS.BALANCE({address}),
    queryFn : async () => {
      if (!address) throw new Error("Invalid params")


      const balanceOf = await new HavahService().api.balance({address})

      console.log("::: [ HAVAH QUERY - BALANCE ] ::: - response ", {
        balanceOf
      });

      return {
        balanceOf
      }
    },
  }),
  tokenAllowance: ({address, tokenContract, spenderContract}: HavahTokenAllowanceQueryParams, options?: ParamQueryOptions) => queryOptions<HavahTokenAllowanceQueryResponse>({
    ...options,
    queryKey: HAVAH_QUERY_KEYS.TOKEN_ALLOWANCE({address, tokenContract, spenderContract}),
    queryFn : async () => {
      if (!address || !tokenContract || !spenderContract) throw new Error("Invalid params")

      const tokenAllowanceCall = new CallBuilder()
        .from(address)
        .to(tokenContract)
        .method("allowance")
        .params({
          _owner  : address,
          _spender: spenderContract,
        })
        .build();

      const res = await new HavahService().api.tokenAllowance({tokenAllowanceCall: tokenAllowanceCall})

      console.log("::: [ HAVAH QUERY - TOKEN ALLOWANCE ] ::: - response ", res);

      return BigInt(iconToHex(res))
    },
  }),
  getApprovedNft: ({address, tokenId}: HavahGetApprovedNftQueryParams, options?: ParamQueryOptions) => queryOptions<HavahGetApprovedNftQueryResponse>({
    ...options,
    queryKey: HAVAH_QUERY_KEYS.GET_APPROVED_NFT({address, tokenId}),
    queryFn : async () => {
      if (!address || !tokenId) throw new Error("Invalid params")

      const getApprovedCall = await (async () => new CallBuilder()
        .from(address)
        .to(havahNftContract.PERPLAY.contract)
        .method("getApproved")
        .params({
          _tokenId: iconToHex(tokenId),
        })
        .build())();

      const res = await new HavahService().api.getApprovedNft({getApprovedCall: getApprovedCall})

      console.log("::: [ HAVAH QUERY - GET APPROVED NFT ] ::: - response ", res);

      return res
    },
  }),
  txReceipt     : ({address, txHash, refetchInterval, retryCount}: HavahTxReceiptQueryParams, options?: ParamQueryOptions) => queryOptions<HavahTxReceiptQueryResponse>({
    ...options,
    queryKey: HAVAH_QUERY_KEYS.TX_RECEIPT({address, txHash}),
    queryFn : async () => {
      if (!txHash) throw new Error("Invalid params")

      const _refetchInterval = refetchInterval ?? 1000 * 5
      const _maxTimeout      = 1000 * 60
      const _retryCount      = retryCount ?? (_maxTimeout / _refetchInterval) // 12 count

      const {recursiveLongPollingFn} = createRecursiveLongPollingFn()

      const _fn = async () => (
        await new HavahService().api.txReceipt({txHash})
      )

      const _refetchCondition = (_res: HavahTxReceiptResponse) => (
        isEmpty(_res)
        || !_res
        || (typeof _res === "string" && (_res === "pending" || _res === "executing"))
        || (typeof _res !== "object")
      )

      const res = await recursiveLongPollingFn({
        fn              : _fn,
        refetchCondition: _refetchCondition,
        retryCount      : _retryCount,
      }, refetchInterval)


      console.log("::: [ HAVAH QUERY - TRANSACTION RECEIPT ] ::: - response", res);

      if (res === "pending" || res === "executing") return null

      return res === "exit" ? null : res
    },
  }),
  nftList       : ({address}: HavahNftListQueryParams, options?: ParamQueryOptions) => queryOptions<HavahNftListQueryResponse>({
    ...options,
    queryKey: HAVAH_QUERY_KEYS.NFT_LIST({address}),
    queryFn : async () => {
      if (!address) throw new Error("Invalid params")

      const res = await new HavahService().api.nftList({address, contractAddress: havahNftContract.PERPLAY.contract as HavahAddress})

      console.log("::: [ HAVAH QUERY - NFT LIST ] ::: - response", res);

      return res ?? []
    },
  }),
};


/** @Mutation */
export const havahMutationOptions = {
  connect    : (options?: ParamMutationOptions<HavahConnectMutationResponse>) => ({
    ...options,
    mutationFn: async () => {
      const res = await new HavahService().api.connect()

      console.log("::: [ HAVAH MUTATION - CONNECT ] ::: - response, ", res)

      return res
    },
  } as MutationOptions<
    HavahConnectMutationResponse,
    any,
    void
  >),
  signMessage: (address?: HavahAddress, options?: ParamMutationOptions) => ({
    ...options,
    mutationFn: async ({message}) => {
      if (!address) throw new Error("Invalid method or address");

      const res = await new HavahService().api.signMessage(message);

      console.log("::: [ HAVAH MUTATION - SIGN ] ::: - response, ", res);

      return res;
    },
  } as MutationOptions<
    HavahWalletSignMutationResponse,
    any,
    HavahWalletSignMutationVariables
  >),
  sendTx     : <T>(options?: ParamMutationOptions) => ({
    ...options,
    mutationFn: async (variables) => {
      const res = await new HavahService().api.sendTx(variables)

      console.log("::: [ HAVAH MUTATION - SEND TRANSACTION ] ::: - response, ", res)

      return res;
    },
  } as MutationOptions<
    HavahSendTxMutationResponse,
    any,
    HavahSendTxMutationVariables<T>
  >),
};
