import {
  HAVAH_DATA_API_RET_CODE,
  type HavahDataApiResponse,
}                                 from "@resources/@types/common/chains/havah/havahDataApi";
import axios, {
  Axios as AxiosType,
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
  type CreateAxiosDefaults,
  type InternalAxiosRequestConfig
}                                 from 'axios';
import {stringify as qsStringify} from 'qs';

type ResponseType<T> = AxiosResponse<{
  data: T;
  message: string;
  status: number;
}>

class _BaseInstance {
  private api: AxiosType

  get<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.api.get(url, config)
  }

  post<T, D extends any = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.api.post(url, data, config)
  }

  put<T, D extends any = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.api.put(url, data, config)
  }

  patch<T, D extends any = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.api.patch(url, data, config)
  }

  delete<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.api.delete(url, config)
  }

  constructor(
    type: "default" | "havah" | "custom",
    config: ((Omit<CreateAxiosDefaults<any>, "baseURL"> | undefined) & { baseURL: string })
  ) {
    this.api = axios.create(config);

    this.api.defaults.timeout = 1000 * 10;

    this.api.defaults.paramsSerializer = params => {
      const queryString = qsStringify(
        params,
        {
          allowDots  : true,
          arrayFormat: "indices",
          skipNulls  : true
        }
      )

      return queryString.replace(/%5B\d%5D=/g, '=');
    }

    this.api.interceptors.request.use(
      this.successRequestInterceptor,
      this.errorRequestInterceptor
    );

    this.api.interceptors.response.use(
      {
        "default": this.defaultSuccessResponseInterceptor,
        "havah"  : this.havahSuccessResponseInterceptor,
        "custom" : this.customSuccessResponseInterceptor,
      }[type],
      this.errorResponseInterceptor
    );
  }


  /** @REQUEST */
  /** Success */
  private successRequestInterceptor = <T>(
    config: InternalAxiosRequestConfig<T>
  ) => {
    console.debug("::: [[[ AXIOS REQUEST ]]] ::: ", config)

    return config
  };

  /** Error */
  private errorRequestInterceptor = (
    err: AxiosError
  ) => {
    console.error("::: [[[ AXIOS REQUEST ERROR ]]] ::: ", err)

    return Promise.reject(err);
  };

  /** @RESPONSE */
  /** Success */
  /** -- Default */
  private defaultSuccessResponseInterceptor = <T>(
    res: ResponseType<T>
  ): T => {
    console.debug("::: [[[ AXIOS RESPONSE ]]] ::: ", res)

    if (res.data.status !== 200) {
      throw new Error(`Error Code : ${res.data.status} - ${res.data.message}`)
    }

    return res.data.data;
  };

  /** -- HAVAH */
  private havahSuccessResponseInterceptor = <T>(
    res: HavahDataApiResponse<T>
  ): T => {
    console.debug("::: [[[ HAVAH AXIOS RESPONSE ]]] ::: ", res)

    if (res.data.retCode !== HAVAH_DATA_API_RET_CODE.SUCCESS) {
      throw new Error(`Error Code : ${res.data.retCode} - ${res.data.retMessage}`)
    }

    return res.data.result;
  };

  /** -- Custom */
  private customSuccessResponseInterceptor = <T>(
    res: AxiosResponse<T>
  ): T => {
    console.debug("::: [[[ CUSTOM AXIOS RESPONSE ]]] ::: ", res)

    if (!res) {
      throw new Error(`Error Code : ${res} - ${res}`)
    }

    return res.data;
  };

  /** Error */
  private errorResponseInterceptor = (
    err: AxiosError
  ) => {
    console.error("::: [[[ AXIOS RESPONSE ERROR ]]] ::: ", err)

    return Promise.reject(err);
  };
}

class DefaultInstance extends _BaseInstance {
  constructor() {
    super("default", {
      baseURL: import.meta.env.VITE_API_URL
    });
  }
}

class DefaultSocketInstance extends _BaseInstance {
  constructor() {
    super("custom", {
      baseURL: import.meta.env.VITE_API_URL
    });
  }
}

class HavahInstance extends _BaseInstance {
  constructor() {
    super("havah", {
      baseURL: import.meta.env.VITE_HAVAH_DATA_API_URL,
      headers: {
        "X-API-KEY-ID": import.meta.env.VITE_HAVAH_DATA_API_ID,
        "X-API-KEY"   : import.meta.env.VITE_HAVAH_DATA_API_SECRET,
      },
    });
  }
}

class PerplayNftInstance extends _BaseInstance {
  constructor() {
    super("custom", {
      // baseURL: "https://meta.service.test.perplay.io/nft",
      baseURL: "",
    });
  }
}


export {
  DefaultInstance,
  DefaultSocketInstance,
  HavahInstance,
  PerplayNftInstance,
}
