import loadable from "@loadable/component";

import Root from './Root';

/** @BRIDGE */
const NFTPage             = loadable(() => import('./bridge/NftPage'), {})
const TokenPage           = loadable(() => import('./bridge/FungibleTokenPage'), {})
const ExplorerPage        = loadable(() => import('./bridge/ExplorerPage'), {})
const ExplorerDetailsPage = loadable(() => import('./bridge/ExplorerDetailsPage'), {})
/** @SWAP */
const SwapPage            = loadable(() => import('./swap/SwapPage'), {})
const FarmPage            = loadable(() => import('./swap/FarmPage'), {})
const LiquidPoolPage      = loadable(() => import('./swap/LiquidPoolPage'), {})

export {
  Root,
  /** @BRIDGE */
    NFTPage,
  TokenPage,
  ExplorerPage,
  ExplorerDetailsPage,
  /** @SWAP */
    SwapPage,
  FarmPage,
  LiquidPoolPage,

}
