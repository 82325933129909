import type {PopupStatus}        from "@resources/@types/common/constant";
import type {AssertionConstType} from "@resources/@types/common/type";
import type {
  ErrorPayload,
  SuccessPayload,
  WarningPayload,
  InfoPayload
}                                from "@resources/@types/toast/toastPayload";

export const TOAST_TYPE = {
  /** @COMMON */
  SUCCESS: 'SUCCESS_TOAST',
  ERROR  : 'ERROR_TOAST',
  WARNING: 'WARNING_TOAST',
  INFO   : 'INFO_TOAST',
  /** @ */
} as const;

export type ToastType = AssertionConstType<typeof TOAST_TYPE>

export type ToastOptionsProps<T extends "default" | "inComponent"> = T extends "default"
  ? {
    totalTimeout?: {
      enter?: number;
      stay?: number;
      exit?: number;
    }
  }
  : {
    enter: number;
    exit: number;
  };


type CommonToastProps<T extends ToastType = ToastType> = {
  type: T;
  hash: string;
  payload: ToastPayloadByType<T>;
}

export type Toast<T extends ToastType = ToastType> = CommonToastProps<T> & {
  status?: PopupStatus
  options?: ToastOptionsProps<"default">
}


export type GetToastComponentPropsType<T extends ToastType = ToastType> = CommonToastProps<T> & {
  inProp: boolean;
  timeout: ToastOptionsProps<"inComponent">
}

export type ToastComponentPropsType<T extends ToastType = ToastType> = Omit<CommonToastProps<T>, "type" | "hash"> & {
  inProp: boolean;
  timeout: ToastOptionsProps<"inComponent">
}


type ToastPayloadMap = {
  [TOAST_TYPE.SUCCESS]: SuccessPayload;
  [TOAST_TYPE.WARNING]: WarningPayload;
  [TOAST_TYPE.ERROR]: ErrorPayload;
  [TOAST_TYPE.INFO]: InfoPayload;
};

export type ToastPayloadByType<T extends ToastType> = ToastPayloadMap[T];


