import type {Hash} from "viem";
import {z}         from "zod";

export const CHAIN_TYPE = {
  EVM  : "EVM_CHAIN",
  HAVAH: "HAVAH_CHAIN",
} as const

export const ChainType = z.nativeEnum(CHAIN_TYPE)
export type ChainType = z.infer<typeof ChainType>

export const TX_RECEIPT_STATUS = {
  SUCCESS  : "Success",
  FAILURE  : "Failure",
  NOT_FOUND: "Not Found",
} as const

export const TxReceiptStatus = z.nativeEnum(TX_RECEIPT_STATUS)
export type TxReceiptStatus = z.infer<typeof TxReceiptStatus>
