import {
  COMPARE_RETURN,
  type CompareReturn,
  ORIGIN_DECIMAL
} from "@resources/@types/common/constant";
import type {
  Query,
  QueryKey
} from "@tanstack/react-query";
import {
  decimalToBigInt,
  formatToDecimalString
} from "@utils/Format";

export const getPublicImgUrl = (url: string) => new URL(url, `${window.location.origin}/`).href

const images = import.meta.glob('../assets/**/*.(png|jpe?g|svg|gif|bmp|tiff|webp)', {eager: true}) as Record<string, { default: string }>;
type ImageAssets = typeof images;

export function getImageUrl<T extends keyof ImageAssets = keyof ImageAssets>(path: T) {

  const _relativePath = path.replace(/^src\//, '');
  const _key          = Object.keys(images).find(k => k.endsWith(_relativePath));
  return _key ? images[_key].default : '';
}

export const delay = (ms: number) => new Promise(resolve => setTimer(resolve, ms));

export const removeSpace = (value: string) => {
  return value.replace(/\s/g, "");
}


export const compareDecimalString = (standard: string | bigint | number, compare: number | bigint | string): CompareReturn => {
  const _bigStandard = typeof (standard) === 'bigint' ? standard : decimalToBigInt(standard);
  const _bigCompare  = typeof (compare) === 'bigint' ? compare : decimalToBigInt(compare);

  if (_bigStandard > _bigCompare) return COMPARE_RETURN.PLUS;
  else if (_bigStandard < _bigCompare) return COMPARE_RETURN.MINUS;
  else return COMPARE_RETURN.EQUAL;
}

export const plus = (a: number | string, b: number | string, decimal?: number): string => {
  const _decimal = decimal ?? ORIGIN_DECIMAL;

  const _decimalBigA = decimalToBigInt(a);
  const _decimalBigB = decimalToBigInt(b);
  return formatToDecimalString((_decimalBigA + _decimalBigB), _decimal);
}

export const minus = (a: number | string, b: number | string, decimal?: number): string => {
  const _decimal = decimal ?? ORIGIN_DECIMAL;

  const _decimalBigA = decimalToBigInt(a);
  const _decimalBigB = decimalToBigInt(b);
  return formatToDecimalString((_decimalBigA - _decimalBigB), _decimal);
}

export const multiply = (a: number | string, b: number | string, decimal?: number): string => {
  const _decimal = decimal ?? ORIGIN_DECIMAL;

  const _decimalBigA = decimalToBigInt(a);
  const _decimalBigB = decimalToBigInt(b);

  const result = (_decimalBigA * _decimalBigB) / BigInt(10 ** _decimal);

  return formatToDecimalString(result, _decimal);
}

export const divide = (a: number | string, b: number | string, decimal?: number): string => {
  const _decimal = decimal ?? ORIGIN_DECIMAL;

  const _decimalBigA = decimalToBigInt(a);
  const _decimalBigB = decimalToBigInt(b);

  const result = (_decimalBigA * BigInt(10 ** _decimal)) / _decimalBigB;

  return formatToDecimalString(result, _decimal);
}

export const progressPercent = ({startPercent, setFn, setCurrentPercent, limit, delay}: {
  startPercent: number;
  setFn: (progress: number) => void;
  setCurrentPercent?: (value: number) => void;
  limit?: number;
  delay?: number;
}) => {
  const _limit = limit ?? 100;
  const _delay = delay ?? 25;

  let _curPercent           = startPercent;
  let _rafId: number | null = null;
  let _lastUpdateTime       = performance.now();

  const updateProgress = (currentTime: number) => {
    const elapsedTime = currentTime - _lastUpdateTime;

    if (elapsedTime >= _delay) {
      if (_curPercent < _limit) {
        _curPercent++;
        setFn(_curPercent);
        if (setCurrentPercent) setCurrentPercent(_curPercent);
        _lastUpdateTime = currentTime;
      } else {
        _curPercent = _limit;
        setFn(_limit);
        if (setCurrentPercent) setCurrentPercent(_limit);
        stop();
        return;
      }
    }

    _rafId = requestAnimationFrame(updateProgress);
  };

  const start = () => {
    if (_rafId === null) {
      _rafId = requestAnimationFrame(updateProgress);
    }
  };

  const stop = () => {
    if (_rafId !== null) {
      cancelAnimationFrame(_rafId);
      _rafId = null;
    }
  };

  const forceComplete = () => {
    stop();
    _curPercent = _limit;
    setFn(_limit);
    if (setCurrentPercent) setCurrentPercent(_limit);
  };

  start();

  return {
    stop,
    forceComplete
  };
};

export const waitForCompletion = async (condition: any, delay?: number) => await new Promise((resolve) => {
  const checkCompletion = () => {
    if (typeof condition === 'function') {
      if (condition()) {
        resolve(true);
      } else {
        setTimer(checkCompletion, delay ?? 100); // 100ms 마다 체크
      }
    } else {
      if (condition) {
        resolve(true);
      } else {
        setTimer(checkCompletion, delay ?? 100); // 100ms 마다 체크
      }
    }
  };
  checkCompletion();
});

export const setTimer = (callback: (value?: any) => any, delay?: number) => ((callback, delay) => {
  const _timer = setTimeout(() => {
    clearTimeout(_timer)
    return callback()
  }, delay ?? 300)
})(callback, delay)

export const toFirstCapitalizeCase = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const createRecursiveLongPollingFn = () => {
  let fetchCount               = 0;
  const recursiveLongPollingFn = async <T extends unknown>({fn, refetchCondition, retryCount}: {
    fn: () => Promise<T>
    refetchCondition: (res: T) => boolean
    retryCount: number;
  }, delay?: number): Promise<T | "exit"> => {
    try {
      fetchCount++;

      if (fetchCount > retryCount) {
        console.debug("Maximum refetch count reached.");
        return "exit";
      }

      const response = await fn();
      if (!refetchCondition(response)) {
        return response;
      }

      await new Promise(resolve => setTimeout(resolve, delay ?? 1000 * 5));
      return recursiveLongPollingFn({fn, refetchCondition, retryCount}, delay);
    } catch (error) {
      console.error('::: [ Error in Recursive Long Polling ] :::', error);
      throw error;
    }
  };


  return {recursiveLongPollingFn};
};


export const predicateByQueryKey = (targetKey: any) => (query: Query<unknown, Error, unknown, QueryKey>) => {
  if (!Array.isArray(query.queryKey)) return false;

  return query.queryKey.some(_el => {
    if (typeof targetKey === 'string') {
      return _el === targetKey;
    }

    if (typeof _el === 'object' && _el) {
      return Object.entries(targetKey).every(
        ([key, value]) => _el[key] === value
      );
    }

    return false;
  })
}