import {Modal}          from "@components/modals";
import {POPUP_STATUS}   from "@resources/@types/common/constant";
import {
  useModalGetOnLength,
  useModalList
}                       from "@stores/modal/store";
import {type ReactNode} from 'react';
import {createPortal}   from "react-dom";
import {RemoveScroll}   from "react-remove-scroll";


const ModalContainer = () => (
  <_Portal>
    <_Modals/>
  </_Portal>
)

const _Portal = ({children}: { children: ReactNode | ReactNode[] }) => {
  const modalContainer = document.getElementById('root-modal-container') as HTMLElement;


  return createPortal(
    children,
    modalContainer
  );
};


const _Modals = () => {

  const {modalList} = useModalList();

  const {onLength} = useModalGetOnLength()

  return modalList.map(
    ({...modal}, index) => (
      modal.status === POPUP_STATUS.ON &&
      <Modal key={index} {...modal} onModalLength={onLength}/>
    )
  )
};

export default ModalContainer;
