import {parse}  from "@utils/JSON";
import CryptoJS from 'crypto-js';

const SECRET_KEY         = "9j30sd2sd_perflex_frontend_crypto_secret_key_138h98h1eed90j" as const;
const STORAGE_SECRET_KEY = "sdd2ef89_perflex_frontend_crypto_storage_secret_key_-90j109j2fgh89h23f" as const;

export const encrypt = (
  target: string | CryptoJS.lib.WordArray
) => CryptoJS.AES.encrypt(
  target,
  SECRET_KEY
).toString();

export const decrypt = (
  target: string | CryptoJS.lib.CipherParams
) => CryptoJS.AES.decrypt(
  target,
  SECRET_KEY
).toString(CryptoJS.enc.Utf8);

export const storageEncrypt = (
  target: string | CryptoJS.lib.WordArray
) => parse(import.meta.env.VITE_CONSOLE_DROP)
  ? CryptoJS.AES.encrypt(
    target,
    STORAGE_SECRET_KEY
  ).toString()
  : target as string;

export const storageDecrypt = (
  target: string | CryptoJS.lib.CipherParams
) => parse(import.meta.env.VITE_CONSOLE_DROP)
  ? CryptoJS.AES.decrypt(
    target,
    STORAGE_SECRET_KEY
  ).toString(CryptoJS.enc.Utf8)
  : target as string;
