import type {AssertionConstType} from "@resources/@types/common/type";
import {CallTransaction}         from "icon-sdk-js";
import {z}                       from "zod";

export const HAVAH_UNSIGNED_TX_METHOD_TYPE = {
  APPROVE: "approve",
  LOCK   : "lock",
} as const

export type HavahUnsignedTxMethodType = AssertionConstType<typeof HAVAH_UNSIGNED_TX_METHOD_TYPE>

export type HavahUnsignedTx<
  data extends unknown = unknown,
> = Pick<CallTransaction,
  | "from"
  | "to"
  | "nid"
  | "version"
  | "timestamp"
  | "value"
  | "dataType"
> & {
      data: data;
    }

export type HavahUnsignedTxData<txType extends HavahUnsignedTxMethodType, data extends unknown = unknown> = {
  method: txType;
  params: data
}


export const HAVAH_TX_RECEIPT_STATUS = {
  SUCCESS: 1,
  FAILURE: 0,
} as const

export const HavahTxReceiptStatus = z.nativeEnum(HAVAH_TX_RECEIPT_STATUS)
export type HavahTxReceiptStatus = z.infer<typeof HavahTxReceiptStatus>
