import loadable from "@loadable/component";

const DesktopLayout = loadable(() => import('./DesktopLayout'), {})
const MobileLayout  = loadable(() => import('./MobileLayout'), {})


export {
  DesktopLayout,
  MobileLayout,
}
