import {
  chainLogos,
  chains,
  defaultChain
}                                   from "@resources/config/contracts";
import {
  metaMask,
  rainbow,
  trustWallet,
  uniSwap
}                                   from "@resources/config/wallet-connectors";
import {createSyncStoragePersister} from "@tanstack/query-sync-storage-persister";
import {type QueryClient}           from "@tanstack/react-query";
import {PersistQueryClientProvider} from "@tanstack/react-query-persist-client";
import {getImageUrl}                from "@utils/Utils";
import {
  injected,
  walletConnect
}                                   from "@wagmi/connectors";
import {
  custom,
  type Transport
}                                   from "@wagmi/core";
import {createWeb3Modal}            from "@web3modal/wagmi/react";
import {type ReactNode}             from "react";
import {
  createWalletClient,
  fallback,
  http,
}                                   from "viem";
import {
  createConfig,
  WagmiProvider
}                                   from "wagmi";


const projectId = import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID;

const icons = getImageUrl("src/images/shortcut-icon")

const metadata: { name: string; description: string; url: string; icons: string[]; } = {
  name       : "PERPLAY PERFLEX",
  description: "PERPLAY PERFLEX Bridge/Swap DWeb Application",
  url        : `${import.meta.env.VITE_HOST}:${import.meta.env.VITE_SERVER_PORT}`,
  icons      : [`${import.meta.env.VITE_HOST}:${import.meta.env.VITE_SERVER_PORT}/images/shortcut-icon.svg`],
};


export const wagmiConfig = createConfig({
  chains            : chains,
  connectors        : [
    walletConnect({
      projectId,
      metadata,
      showQrModal: false,
    }),
    injected({shimDisconnect: true}),
  ],
  syncConnectedChain: false,
  client            : ({chain}) => {
    const _transport = [] as Transport[]

    _transport.push(http(chain.rpcUrls.default.http[0]))
    _transport.push(custom(window.ethereum))
    // if (
    //   !!chain.rpcUrls.default.webSocket
    //   && !!chain.rpcUrls.default.webSocket.length
    //   && !!chain.rpcUrls.default.webSocket[0]
    // ) {
    //   _transport.push(webSocket(chain.rpcUrls.default.webSocket[0]))
    // }
    _transport.push(http())


    return createWalletClient({
      chain,
      transport: fallback([
        ..._transport
      ])
    })
  },
})


export const web3Modal = createWeb3Modal({
  projectId            : projectId,
  wagmiConfig          : wagmiConfig,
  defaultChain         : defaultChain,
  allowUnsupportedChain: false,
  allWallets           : "SHOW",
  featuredWalletIds    : [
    metaMask.id,
    trustWallet.id,
    rainbow.id,
  ],
  chainImages          : chains.reduce((acc, cur) => {
    acc[cur.id] = `${chainLogos[cur.id]}`
    return acc
  }, {} as Record<number, string>),
  connectorImages      : {
    [metaMask.id]        : trustWallet.icon, // Explorer
    "metaMaskSDK"        : metaMask.icon, // Default Internal SDK
    "io.metamask"        : metaMask.icon, // Injected
    [trustWallet.id]     : trustWallet.icon, // Explorer
    "com.trustwallet.app": trustWallet.icon, // Injected
    [rainbow.id]         : rainbow.icon, // Explorer
    "me.rainbow"         : rainbow.icon, // Injected
    [uniSwap.id]         : uniSwap.icon, // Explorer
    "org.uniswap.app"    : uniSwap.icon, // Injected
  },
  themeVariables       : {
    '--w3m-font-family': 'Pretendard, sans-serif',
  },
})


interface PropsType {
  qc: QueryClient
  children?: ReactNode
}

const asyncStoragePersister = createSyncStoragePersister({
  storage: sessionStorage,
});

const WagmiReactQueryContainer = ({
  qc,
  children
}: PropsType) => (
  <WagmiProvider config={wagmiConfig} reconnectOnMount>
    <PersistQueryClientProvider
      client={qc}
      persistOptions={{
        persister       : asyncStoragePersister,
        maxAge          : 1000 * 60 * 60, // 1시간
        dehydrateOptions: {
          shouldDehydrateQuery   : (query) => (
            !!query.options.meta?.persist
          ),
          shouldDehydrateMutation: (mutation) => (
            !!mutation.options.meta?.persist
          ),
        },
      }}
    >
      {children}
    </PersistQueryClientProvider>
  </WagmiProvider>
)

export default WagmiReactQueryContainer;
