import {useRef} from "react";

const useRootEl = () => {
  const $docRef  = useRef<HTMLElement>(document.documentElement)
  const $bodyRef = useRef<HTMLElement>(document.body)
  const $rootRef = useRef<HTMLElement>(document.getElementById('root')!)

  return {
    $docRef : $docRef,
    $bodyRef: $bodyRef,
    $rootRef: $rootRef,
  }
}

export default useRootEl;
