import {
  type DependencyList,
  useEffect,
  useRef
} from "react";

type HookType = (
  fn: Function,
  deps: DependencyList
) => void

const useDidMountEffect: HookType = (
  fn,
  deps,
) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) fn();
    else didMount.current = true;
  }, deps)
}

export default useDidMountEffect;
