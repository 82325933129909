import type {InfoFee}                        from "@components/UI/molecules/TransferFee";
import type {ArbitrumOneUnsignedTx}          from "@resources/@types/common/chains/evm/arbitrum";
import type {ZkSyncUnsignedTx}               from "@resources/@types/common/chains/evm/zkSync";
import {
  HAVAH_UNSIGNED_TX_METHOD_TYPE,
  type HavahUnsignedTx,
  type HavahUnsignedTxData
}                                            from "@resources/@types/common/chains/havah/havah";
import {
  type CompareSymbol,
  type DestinationWalletType,
  type SuccessOrFailure,
}                                            from "@resources/@types/common/constant";
import {
  type BalanceInfo,
  GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME,
  type GetEvmUnsignedTxMutationVariablesChainName,
  GetUnsignedTxMutationVariablesChainName,
  type UnsignedTxFees
}                                            from "@resources/@types/common/query";
import {BalanceAmount}                       from "@resources/@types/common/schema";
import type {
  AssertionConstType,
  EvmAddress,
  HashTypes,
  HexTypes,
  Receipt
}                                            from "@resources/@types/common/type";
import {
  AddressTypes,
  HavahAddress,
}                                            from "@resources/@types/common/type";
import {FtTransferModalResultTxHashesSchema} from "@stores/fungible-token/schema";
import {
  isEvmAddress,
  isIconEoaAddress
}                                            from "@utils/Validator";
import type {ReactNode}                      from "react";
import type {Hash}                           from "viem";
import {z}                                   from "zod";

export const FT_STEP_NAV = {
  ONE        : 1,
  TWO        : 2,
  THREE      : 3,
  NEXT       : 4,
  ON_TRANSFER: 5,
} as const
export type FtStepNav = AssertionConstType<typeof FT_STEP_NAV>


type ChainNames = GetUnsignedTxMutationVariablesChainName
type EvmChainNames = GetEvmUnsignedTxMutationVariablesChainName

/** @GET-UNSIGNED-CONTAINER **/
type Account = {
  isConnected: boolean;
  hasAddress: boolean;
}

type FtMakeRequestVariablesProps = Omit<CommonGetFtUnsignedTxContainerPropsType, "children"> & {
  evm: Account & { address?: EvmAddress };
  havah: Account & { address?: HavahAddress };
  canSkipApprove: boolean
}

export type FtMakeRequestVariablesReturn =
  FtGetUnsignedBridgeLockTxMutationVariables
  & {
    canSkipApprove: boolean
  }

export type FtMakeRequestVariables = (props: FtMakeRequestVariablesProps) => Promise<false | FtMakeRequestVariablesReturn>


export type GetFtGetUnsignedTxContainerByChainChildrenPropsType = {
  makeRequestVariables: () => Promise<false | FtMakeRequestVariablesReturn>
}

export type GetFtGetUnsignedTxContainerByChainChildrenType = (props: GetFtGetUnsignedTxContainerByChainChildrenPropsType) => ReactNode

export type CommonGetFtUnsignedTxContainerPropsType = {
  destinationChainId?: number;
  tokenContract?: AddressTypes;
  tokenId: string;
  tokenAmount: string;
  tokenName: string;
  walletType: DestinationWalletType
  otherWalletAddress: string;
  children: GetFtGetUnsignedTxContainerByChainChildrenType
}

export type GetFtGetUnsignedTxContainerByChainPropsType = CommonGetFtUnsignedTxContainerPropsType & {
  sourceChainId: number;
}


/** @SIGNING-CONTAINER **/
export type FungibleTokenInfoFees = {
  myBalance: InfoFee;
  // transactionFee: InfoFee;
  // remainBalance: InfoFee;
  transferAmount: InfoFee;
  commissionFee: InfoFee;
  destinationFee: InfoFee;
  receivableAmount: [compareSymbol: CompareSymbol, ...InfoFee];
  isBalanceSufficient: boolean
  isReceivableAmountPositive: boolean
}


export type FtReceiptInfo = {
  isLoading: boolean;
  receipt: Receipt
}

export type FtReceipts = {
  sourceApproveTxReceipt: FtReceiptInfo & {
    useReceipt: boolean;
  };
  sourceTxByChainReceipt: FtReceiptInfo;
  destinationTxByChainReceipt: FtReceiptInfo & {
    canRefetch: boolean;
    refetch: () => void;
  };
}

export type GetFtSigningContainerByChainChildrenPropsType = (props: {
  onClickTransfer: () => void;
  infoFees: FungibleTokenInfoFees
  receipts: FtReceipts
}) => ReactNode


export type FtSigningContainerPropsType<chainName extends ChainNames = ChainNames, T extends boolean = boolean> = {
  sourceChainId: number;
  children: GetFtSigningContainerByChainChildrenPropsType;
  fees: UnsignedTxFees;
  fromAddress: chainName extends typeof GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH ? HavahAddress : EvmAddress;
  toAddress: chainName extends typeof GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH ? EvmAddress : AddressTypes;
  isFirstRequestApprove: T
  unsignedFtApproveTx: T extends true
    ? chainName extends typeof GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH ? HavahFtUnsignedApproveTx : EvmFtUnsignedTx<Extract<chainName, EvmChainNames>>
    : undefined;
  unsignedFtBridgeLockTx: T extends true
    ? undefined
    : chainName extends typeof GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH ? HavahFtUnsignedBridgeLockTx : EvmFtUnsignedTx<Extract<chainName, EvmChainNames>>
}

export type GetFtSigningContainerByChainPropsType = FtSigningContainerPropsType

export type FtResultInfoFn = (info: {
  type?: SuccessOrFailure,
  storeTxHashInfo?: {
    storeKey: z.infer<ReturnType<typeof FtTransferModalResultTxHashesSchema.keyof>>;
    txHash: HashTypes
  }
}) => void


export type FtMakeFeesFn = (arg: {
  dataFees?: UnsignedTxFees;
  balanceInfo?: BalanceInfo;
  targetTokenInfo: {
    symbol: string;
    amount: string;
  }
}) => FungibleTokenInfoFees

export type FtTransferFnPropsType<chainName extends ChainNames> =
  Omit<FtSigningContainerPropsType<chainName>, "fees" | "children">
  & {
    tokenName: string;
    tokenAmount: string;
  }
export type FtTransferFnSecondSequencePropsType<chainName extends ChainNames> =
  Omit<FtTransferFnPropsType<chainName>, "unsignedFtApproveTx" | "isFirstRequestApprove">
  & {
    canCallGetTxApi: boolean;
  }

/** @EVM */
type EvmChainTxTypes<T extends EvmChainNames> = {
  [GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.ZK_SYNC]: ZkSyncUnsignedTx;
  [GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.ARBITRUM]: ArbitrumOneUnsignedTx;
}[T]
export type EvmFtUnsignedTx<evmChainName extends EvmChainNames = EvmChainNames> = EvmChainTxTypes<evmChainName>

/** @HAVAH */
export interface HavahFtUnsignedApproveTxDataParams {
  _spender: HavahAddress;
  _value: HexTypes;
}

export interface HavahFtUnsignedBridgeLockTxDataParams {
  // from: AddressTypes;
  // commissionAmount: HexTypes;
  // destinationFeeAmount: HexTypes;
  to: AddressTypes;
  amount: HexTypes;
  signatureArray: string[];
  uuid: string;
}


export type HavahFtUnsignedApproveTxData = HavahUnsignedTxData<typeof HAVAH_UNSIGNED_TX_METHOD_TYPE.APPROVE, HavahFtUnsignedApproveTxDataParams>
export type HavahFtUnsignedApproveTx = HavahUnsignedTx<HavahFtUnsignedApproveTxData>

export type HavahFtUnsignedBridgeLockTxData = HavahUnsignedTxData<typeof HAVAH_UNSIGNED_TX_METHOD_TYPE.LOCK, HavahFtUnsignedBridgeLockTxDataParams>
export type HavahFtUnsignedBridgeLockTx = HavahUnsignedTx<HavahFtUnsignedBridgeLockTxData>

export type HavahFtSendTxMutationVariablesGeneric = HavahFtUnsignedApproveTxData | HavahFtUnsignedBridgeLockTxData


/** @Response-And-Params */
//== Approve
export type FtGetUnsignedApproveTxMutationResponse<chainName extends ChainNames> = UnsignedTxFees & {
  unsignedFtApproveTransaction: chainName extends typeof GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH
    ? HavahFtUnsignedApproveTx
    : EvmFtUnsignedTx<Extract<chainName, EvmChainNames>>
}

export const FtGetUnsignedApproveTxMutationVariables = z.object({
  chainName: GetUnsignedTxMutationVariablesChainName,
  from     : AddressTypes,
  tokenName: z.string(),
  amount   : z.string(),
}).refine(data => {
  const {data: parsedChainName, success, error} = GetUnsignedTxMutationVariablesChainName.safeParse(data.chainName)

  if (!parsedChainName || !success || error) return false;

  if (parsedChainName === GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH) {
    return isIconEoaAddress(data.from) && !!BalanceAmount.safeParse(data.amount)?.data
  } else {
    return isEvmAddress(data.from) && !!BalanceAmount.safeParse(data.amount)?.data
  }
})
export type FtGetUnsignedApproveTxMutationVariables = z.infer<typeof FtGetUnsignedApproveTxMutationVariables>;

//== Bridge Lock
export type FtGetUnsignedBridgeLockTxMutationResponse<chainName extends ChainNames> = UnsignedTxFees & {
  unsignedFtBridgeLockTransaction: chainName extends typeof GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH
    ? HavahFtUnsignedBridgeLockTx
    : EvmFtUnsignedTx<Extract<chainName, EvmChainNames>>
}

export const FtGetUnsignedBridgeLockTxMutationVariables = z.object({
  chainName: GetUnsignedTxMutationVariablesChainName,
  from     : AddressTypes,
  to       : AddressTypes,
  tokenName: z.string(),
  amount   : z.string(),
}).refine(data => {
  const {data: parsedChainName, success, error} = GetUnsignedTxMutationVariablesChainName.safeParse(data.chainName)

  if (!parsedChainName || !success || error) return false;

  if (data.chainName === GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH) {
    return (
      isIconEoaAddress(data.from)
      && isEvmAddress(data.to)
      && !!BalanceAmount.safeParse(data.amount)?.data
    )
  } else {
    return (
      isEvmAddress(data.from)
      && (isEvmAddress(data.to) || isIconEoaAddress(data.to))
      && !!BalanceAmount.safeParse(data.amount)?.data
    )
  }
})

export type FtGetUnsignedBridgeLockTxMutationVariables = z.infer<typeof FtGetUnsignedBridgeLockTxMutationVariables>;

export interface FtSendSignedTxResponse {
  transactionHash: Hash
}

export const FtSendSignedTxVariables = z.object({
  chainName        : z.custom<EvmChainNames>((val): val is EvmChainNames => val !== GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH),
  signedTransaction: AddressTypes,
})
export type FtSendSignedTxVariables = z.infer<typeof FtSendSignedTxVariables>

// export type FtSendSignedTxVariables<chainName extends GetUnsignedTxMutationVariablesChainName = GetUnsignedTxMutationVariablesChainName> =
//   {
//     chainName: typeof GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.ZK_SYNC;
//     signedTransaction: AddressTypes;
//   }
// (chainName extends typeof GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.ZK_SYNC
//   ? {
//     chainName: typeof GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.ZK_SYNC;
//     signedTransaction: AddressTypes;
//   }
//   : {
//     chainName: typeof GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH;
//     signedTransaction: HavahFungibleTokenSignedTx;
//   })







