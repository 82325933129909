import {
  forwardRef,
  type HTMLAttributes,
  type ReactNode
} from "react";

interface PropsType extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode
}

const Div = forwardRef<HTMLDivElement, PropsType>((
  props,
  ref,
) => (
  <div ref={ref} {...props}/>
))

export default Div;
