import {
  type ContractInfos,
  type DefineCommonChainInfo,
  EvmContractInfo,
  type TokenPairs
} from "@resources/@types/common/type";
import {
  defineContractInfo,
  defineSwapPair
} from "@resources/config/contracts/config";
import {
  zkSync,
  zkSyncSepoliaTestnet
} from "wagmi/chains";

export const zkSyncEra = import.meta.env.VITE_WEB3_NETWORK_TYPE !== "mainnet" ? zkSyncSepoliaTestnet : zkSync

const defaultConfig: DefineCommonChainInfo = {
  chainId     : zkSyncEra.id,
  chainName   : zkSyncEra.name,
  chainTestnet: zkSyncEra.testnet
}

/** @BRIDGE */
const zkBridge = defineContractInfo({
  ...defaultConfig,
  name         : "ZK Bridge",
  id           : "zkSyncEra-zk-bridge",
  symbol       : "ZK",
  contract     : "0xc275B23C035a9d4EC8867b47f55427E0bDCe14cB",
  devContract  : "0x6fFa792C5CE9fF7A3D15F7Ed77900F42f602B81D",
  stageContract: "0x6fFa792C5CE9fF7A3D15F7Ed77900F42f602B81D",
}) as EvmContractInfo

const perBridge = defineContractInfo({
  ...defaultConfig,
  name         : "PER Bridge",
  id           : "zkSyncEra-per-bridge",
  symbol       : "PER",
  contract     : "0xAa63A3C3175806De19A7fC48FbCAFd9bb6aC5096",
  devContract  : "0xAa63A3C3175806De19A7fC48FbCAFd9bb6aC5096",
  stageContract: "0xAa63A3C3175806De19A7fC48FbCAFd9bb6aC5096",
}) as EvmContractInfo

const xperBridge = defineContractInfo({
  ...defaultConfig,
  name         : "XPER Bridge",
  id           : "zkSyncEra-xper-bridge",
  symbol       : "XPER",
  contract     : "0xC2cD8FEDC9FFDC6354e15Ccf6E600e0b2b863e50",
  devContract  : "0xC2cD8FEDC9FFDC6354e15Ccf6E600e0b2b863e50",
  stageContract: "0xC2cD8FEDC9FFDC6354e15Ccf6E600e0b2b863e50",
}) as EvmContractInfo

const perplayNftBridge = defineContractInfo({
  ...defaultConfig,
  name         : "XPER Bridge",
  id           : "zkSyncEra-xper-bridge",
  symbol       : "XPER",
  contract     : "0xC2cD8FEDC9FFDC6354e15Ccf6E600e0b2b863e50",
  devContract  : "0xC2cD8FEDC9FFDC6354e15Ccf6E600e0b2b863e50",
  stageContract: "0xC2cD8FEDC9FFDC6354e15Ccf6E600e0b2b863e50",
}) as EvmContractInfo

export const zkSyncEraBridges = {
  ARB_BRIDGE        : zkBridge,
  PER_BRIDGE        : perBridge,
  XPER_BRIDGE       : xperBridge,
  PERPLAY_NFT_BRIDGE: perplayNftBridge,
} as const


/** @TOKEN */
const perToken = defineContractInfo({
  ...defaultConfig,
  name         : "PER",
  id           : "zkSyncEra-per-token",
  symbol       : "PER",
  contract     : "0x209f2D507CB64e8eF4ae1831fEC65a0E9885138e",
  devContract  : "0x7481B0fb6868E026aA6d59dc858e6dEcE3379150",
  stageContract: "0x7481B0fb6868E026aA6d59dc858e6dEcE3379150",
}) as EvmContractInfo

const xperToken = defineContractInfo({
  ...defaultConfig,
  name         : "XPER",
  id           : "zkSyncEra-xper-token",
  symbol       : "XPER",
  contract     : "0xEe6453fbAA019474A984D316788e533fE0DaB853",
  devContract  : "0xEe6453fbAA019474A984D316788e533fE0DaB853",
  stageContract: "0xEe6453fbAA019474A984D316788e533fE0DaB853",
}) as EvmContractInfo


export const zkSyncEraTokens = {
  PER_TOKEN: perToken,
  // XPER_TOKEN: xperToken,
} as const

/** @NFT */
const perplayNft = defineContractInfo({
  ...defaultConfig,
  id           : "zkSyncEra-perplay-nft",
  name         : "PERPLAY",
  symbol       : "VEHICLE",
  contract     : "0x76314dD956a24e2be7424592F3B9d4e2d20eeD0C",
  devContract  : "0x76314dD956a24e2be7424592F3B9d4e2d20eeD0C",
  stageContract: "0x76314dD956a24e2be7424592F3B9d4e2d20eeD0C",
}) as EvmContractInfo


export const zkSyncEraNftContract = {
  PERPLAY: perplayNft,
} as const


/** @SWAP */
export const zkSyncEraSwapPair = defineSwapPair({
  [perToken.id] : [perToken, [xperToken], {indexPair: true}],
  [xperToken.id]: [xperToken, [perToken]],
})

