import {
  DesktopLayout,
  MobileLayout
}                    from "@components/layouts";
import {Div}         from "@components/UI/atoms";
import {
  useDeviceCheck,
  useDocTheme,
  useHavahAccount,
  useWatchAccountChange
}                    from "@hooks";
import {
  HEADER_TOGGLE_MAIN_PAGE,
  HEADER_TOGGLE_MAIN_PAGE_PATH
}                    from "@resources/@types/common/constant";
import {havah}       from "@resources/config/contracts";
import {
  useCommonActions,
  useCommonDestinationChain,
  useCommonIsTouchDevice,
  useCommonSourceChain
}                    from "@stores/common/store";
import {getImageUrl} from "@utils/Utils";
import {useEffect}   from "react";
import {useLocation} from "react-router-dom";
import styles        from './Root.module.scss'

interface PropsType {
}


const Root = ({}: PropsType) => {
  const {pathname}                                   = useLocation()
  const {isTouchDevice}                              = useCommonIsTouchDevice()
  const {sourceChain: {id: sourceChainId}}           = useCommonSourceChain()
  const {destinationChain: {id: destinationChainId}} = useCommonDestinationChain()
  const {disconnect, isConnected}                    = useHavahAccount()
  const {
          resetCommonDestinationChainOtherWallet,
          updateHeaderToggle,
        }                                            = useCommonActions()

  /** @THEME */
  useDocTheme()

  useDeviceCheck()

  useWatchAccountChange()

  useEffect(() => {
    if (pathname.includes(HEADER_TOGGLE_MAIN_PAGE_PATH.BRIDGE)) {
      updateHeaderToggle(HEADER_TOGGLE_MAIN_PAGE.BRIDGE)
    } else if (pathname.includes(HEADER_TOGGLE_MAIN_PAGE_PATH.SWAP)) {
      updateHeaderToggle(HEADER_TOGGLE_MAIN_PAGE.SWAP)
    }
  }, [pathname]);

  useEffect(() => {
    if (isConnected) {
      ;(async () => await disconnect())()
    }
  }, [sourceChainId]);

  useEffect(() => {
    resetCommonDestinationChainOtherWallet();

    if (isConnected && destinationChainId === havah.id) {
      ;(async () => await disconnect())()
    }
  }, [destinationChainId]);


  return (
    <>
      <Div className={styles.rootBgWrap}>
        <picture>
          <source
            srcSet={getImageUrl("src/assets/images/main-bg.webp")}
            className={styles.rootBg}
            type="image/webp"
          />
          <source
            srcSet={getImageUrl("src/assets/images/main-bg.jpg")}
            className={styles.rootBg}
          />
          <img
            alt="PERFLEX Main background image."
            className={styles.rootBg}
          />
        </picture>
      </Div>
      {isTouchDevice
        ? <MobileLayout key="Mobile"/>
        : <DesktopLayout key="Desktop"/>}
    </>
  )
}


export default Root;
