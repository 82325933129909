import loadable from "@loadable/component";

const Modal                      = loadable(() => import("./Modal"));
const QrCodeModal                = loadable(() => import("./common/QrCodeModal"));
const AddressModal               = loadable(() => import("./common/AddressModal"));
const SelectChainModal           = loadable(() => import("./common/SelectChainModal"));
const SelectTokenModal           = loadable(() => import("./common/SelectTokenModal"));
const SelectWalletTypeModal      = loadable(() => import("./common/SelectWalletTypeModal"));
const FungibleTokenTransferModal = loadable(() => import("./fungible-token/FungibleTokenTransferModal"));
const NftTransferModal           = loadable(() => import("./nft/NftTransferModal", {}));
const SelectNftModal             = loadable(() => import("./nft/SelectNftModal"));
const SwapTransferModal          = loadable(() => import("./swap/SwapTransferModal"));
const SwapSlippageSettingModal   = loadable(() => import("./swap/SwapSlippageSettingModal"));

export {
  Modal,
  QrCodeModal,
  AddressModal,
  SelectWalletTypeModal,
  SelectChainModal,
  SelectTokenModal,
  FungibleTokenTransferModal,
  NftTransferModal,
  SelectNftModal,
  SwapTransferModal,
  SwapSlippageSettingModal
}
