import {Toast}          from "@components/toasts";
import {useToastList}   from "@stores/toast/store";
import {type ReactNode} from 'react';
import {createPortal}   from "react-dom";


const ToastContainer = () => (
  <_Portal>
    <_Toasts/>
  </_Portal>
)

const _Portal = ({children}: { children: ReactNode | ReactNode[] }) => {
  const toastContainer = document.getElementById('root-toast-container') as HTMLElement;


  return createPortal(
    children,
    toastContainer
  );
};


const _Toasts = () => {
  const {toastList} = useToastList();

  return toastList.map(
    ({...toast}) => <Toast key={toast.hash} {...toast}/>
  )
};

export default ToastContainer;
