import {FT_STEP_NAV} from "@resources/@types/fungible-token/fungibleToken";
import {
  createFtTransferModalSlice,
  ftTransferModalInitialState
}                    from "@stores/fungible-token/transferModalSlice";
import {
  type Draft,
  produce
}                    from "immer";
import {create}      from "zustand";
import {immer}       from 'zustand/middleware/immer';
import type {
  FungibleTokenMergedStore,
  FungibleTokenMergedStoreInitialState,
  FungibleTokenStoreInitialState
}                    from "./schema";


const initialState: FungibleTokenStoreInitialState = {
  fungibleToken: {
    targetToken      : {
      info  : {
        id      : "",
        name    : "",
        symbol  : "",
        contract: undefined,
      },
      amount: "",
    },
    stepNav          : FT_STEP_NAV.ONE,
    isSubmitBtnActive: false,
  },
};

const useFungibleTokenStore = create<FungibleTokenMergedStore>()(
  immer((set, get, store) => ({
    fungibleToken: {
      ...initialState.fungibleToken,
      ...createFtTransferModalSlice(set, get, store).fungibleToken,
    },
    actions      : {
      updateFtTargetTokenInfo  : (targetTokenInfo) => {
        set(produce((state: Draft<FungibleTokenMergedStoreInitialState>) => {
          state.fungibleToken.targetToken.info = targetTokenInfo;
        }));
      },
      updateFtTargetTokenAmount: (amount) => {
        set(produce((state: Draft<FungibleTokenMergedStoreInitialState>) => {
          state.fungibleToken.targetToken.amount = amount;
        }));
      },
      updateFtStepNav          : (step) => {
        set(produce((state: Draft<FungibleTokenMergedStoreInitialState>) => {
          state.fungibleToken.stepNav = step;
        }));
      },
      updateIsFtSubmitBtnActive: (active) => {
        set(produce((state: Draft<FungibleTokenMergedStoreInitialState>) => {
          state.fungibleToken.isSubmitBtnActive = active;
        }));
      },
      resetFtTargetToken       : () => {
        set(produce((state: Draft<FungibleTokenMergedStoreInitialState>) => {
          const {targetToken}             = initialState.fungibleToken
          state.fungibleToken.targetToken = {
            ...targetToken,
            info: {
              ...targetToken.info,
              contract: undefined
            }
          };
        }));
      },
      resetFtTargetTokenAmount : () => {
        set(produce((state: Draft<FungibleTokenMergedStoreInitialState>) => {
          state.fungibleToken.targetToken.amount = initialState.fungibleToken.targetToken.amount;
        }));
      },
      resetFt                  : () => {
        set(produce((state: Draft<FungibleTokenMergedStoreInitialState>) => {
          state.fungibleToken = {...initialState.fungibleToken, ...ftTransferModalInitialState.fungibleToken};
        }));
      },
      ...createFtTransferModalSlice(set, get, store).actions,
    },
  })));

/**
 * @STATE
 */
export const useFungibleTokenTargetTokenInfo                 = () => useFungibleTokenStore((state) => ({ftTargetTokenInfo: state.fungibleToken.targetToken.info}))
export const useFungibleTokenTargetTokenAmount               = () => useFungibleTokenStore((state) => ({ftTargetTokenAmount: state.fungibleToken.targetToken.amount}))
export const useFungibleTokenIsSubmitBtnActive               = () => useFungibleTokenStore((state) => ({isFtSubmitBtnActive: state.fungibleToken.isSubmitBtnActive}))
export const useFungibleTokenStepNav                         = () => useFungibleTokenStore((state) => ({ftStepNav: state.fungibleToken.stepNav}))
export const useFungibleTokenIsTransferModalOn               = () => useFungibleTokenStore((state) => ({isFtTransferModalOn: state.fungibleToken.transferModal.isOn}))
export const useFungibleTokenTransferModalStep               = () => useFungibleTokenStore((state) => ({ftTransferModalStep: state.fungibleToken.transferModal.step}))
export const useFungibleTokenIsTransferModalTransferring     = () => useFungibleTokenStore((state) => ({isFtTransferModalTransferring: state.fungibleToken.transferModal.isTransferring}))
export const useFungibleTokenTransferModalProgressTitle      = () => useFungibleTokenStore((state) => ({ftTransferModalProgressTitle: state.fungibleToken.transferModal.progress.title}))
export const useFungibleTokenTransferModalProgressPercentage = () => useFungibleTokenStore((state) => ({ftTransferModalProgressPercentage: state.fungibleToken.transferModal.progress.percentage}))
export const useFungibleTokenTransferModalResultType         = () => useFungibleTokenStore((state) => ({ftTransferModalResultType: state.fungibleToken.transferModal.result.type}))
export const useFungibleTokenTransferModalResultTxHashes     = () => useFungibleTokenStore((state) => ({ftTransferModalResultTxHashes: state.fungibleToken.transferModal.result.txHashes}))

/**
 * @ACTIONS
 */
export const useFungibleTokenActions = () => useFungibleTokenStore((state) => state.actions)



