import {IconService} from "icon-sdk-js";
import {
  isAddress as isEvmAddress,
  isAddressEqual as isEvmAddressEqual,
  isHash,
  isHex
}                    from "viem";

const {
        IconValidator
      } = IconService

const {
        isAddress     : isIconAddress,
        isEoaAddress  : isIconEoaAddress,
        isScoreAddress: isIconScoreAddress,
      } = IconValidator




export {
  isIconAddress,
  isIconEoaAddress,
  isIconScoreAddress,
  isHash,
  isHex,
  isEvmAddress,
  isEvmAddressEqual,
}
