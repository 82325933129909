import {POPUP_STATUS}    from "@resources/@types/common/constant";
import type {ToastStore} from "@stores/toast/schema";
import {
  type Draft,
  produce,
}                        from 'immer';
import {create}          from "zustand";
import {immer}           from "zustand/middleware/immer";

type State = Pick<ToastStore, "toast">

const initialState: State = {
  toast: {
    list: [],
  },
};


const useToastStore = create<ToastStore>()(
  immer((set, get) => ({
    ...initialState,
    actions: {
      getToastStatus      : (targetHash) => (
        get().toast.list.filter(({hash}) => hash === targetHash)[0]?.status
      ),
      createToast         : ({hash: targetHash, ...args}) => {
        set(produce((draft: Draft<State>) => {
          const _createdAt = Date.now()

          if (draft.toast.list.some(({hash}) => hash === targetHash)) {
            draft.toast.list = draft.toast.list
              .map(
                (_toast) => (
                  _toast.hash === targetHash
                    ? {
                      ..._toast,
                      idx   : _createdAt,
                      status: POPUP_STATUS.ON
                    }
                    : _toast
                ))
              .sort((a, b) => a.idx - b.idx);

          } else {
            draft.toast.list.push({
              ...args,
              idx   : _createdAt,
              status: POPUP_STATUS.ON,
              hash  : targetHash,
            })

            draft.toast.list
              .sort((a, b) => a.idx - b.idx);

          }
        }));
      },
      updateToastStatusOff: (targetHash) => {
        set(produce((draft: Draft<State>) => {
          if (draft.toast.list.some(({hash}) => hash === targetHash)) {
            draft.toast.list = draft.toast.list.map(
              (_toast) => (
                _toast.hash === targetHash ? {..._toast, status: POPUP_STATUS.OFF} : _toast
              ));

          }
        }));
      },
    },
  })));

/**
 * @STATE
 */
export const useToastList    = () => useToastStore(state => ({toastList: state.toast.list}));
/**
 * @ACTIONS
 */
export const useToastActions = () => useToastStore(state => state.actions);
