import type {
  ContractInfos,
  DefineCommonChainInfo,
  HavahContractInfo
}                           from "@resources/@types/common/type";
import {defineContractInfo} from "@resources/config/contracts/config";
import {defineChain}        from "viem";

const havahMainnet = defineChain({
  id            : 256,
  name          : 'HAVAH',
  network       : 'HAVAH',
  nativeCurrency: {
    decimals: 18,
    name    : 'HAVAH',
    symbol  : 'HVH',
  },
  rpcUrls       : {
    default: {
      http: ['https://ctz.havah.io/api/v3'],
    },
  },
  blockExplorers: {
    default: {
      name: 'HAVAH Scan',
      url : 'https://scan.havah.io',
    },
  },
})

const havahVegaTestnet = defineChain({
  id            : 257,
  name          : 'HAVAH Vega Testnet',
  network       : 'HAVAH',
  nativeCurrency: {
    name    : 'HAVAH',
    symbol  : 'HVH',
    decimals: 18,
  },
  rpcUrls       : {
    default: {
      http: ['https://ctz.vega.havah.io/api/v3'],
    },
  },
  blockExplorers: {
    default: {
      name: 'HAVAH Scan',
      url : 'https://scan.vega.havah.io',
    },
  },
  testnet       : true
})

export const havah = import.meta.env.VITE_WEB3_NETWORK_TYPE !== "mainnet" ? havahVegaTestnet : havahMainnet


const defaultConfig: DefineCommonChainInfo = {
  chainId     : havah.id,
  chainName   : havah.name,
  chainTestnet: havah.testnet,
}


/** @BRIDGE */
const arbBridge = defineContractInfo({
  ...defaultConfig,
  id           : "havah-hvh-bridge",
  name         : "HVH Bridge",
  symbol       : "HVH",
  contract     : "cx7c8a84b1dcab384db888a018955b9b45b643b085",
  devContract  : "cx2e9fae9b2fbac96924bed5003b7b370c37775bdb",
  stageContract: "cx1cffb5699cc35be912ed2802e66abb48198a7041",
}) as HavahContractInfo

const perBridge = defineContractInfo({
  ...defaultConfig,
  id           : "havah-per-bridge",
  name         : "PER Bridge",
  symbol       : "PER",
  contract     : "cx021d6db9389265a4db30f5e5392669a0ffbdbba0",
  devContract  : "cx74cdf901e74f7d8a57e41afb1e6d88252a3bec30",
  stageContract: "cx42f2bace5a5d8bb62bd0b188ebbe3067fb97d610",
}) as HavahContractInfo

const xperBridge = defineContractInfo({
  ...defaultConfig,
  id           : "havah-xper-bridge",
  name         : "XPER Bridge",
  symbol       : "XPER",
  contract     : "cxc7b83a7dfcb2d87a8532bbe92427a1776e93b72b",
  devContract  : "cxe26a1e5634b080d0437c7978fb14a3d4f12d05c5",
  stageContract: "cx85bbfa9e52b1e9e388e69b74b55aed73d61675e6",
}) as HavahContractInfo

const perplayNftBridge = defineContractInfo({
  ...defaultConfig,
  id           : "havah-xper-bridge",
  name         : "XPER Bridge",
  symbol       : "XPER",
  contract     : "cx3d9a42145669d6cf2006f85b20983c0c7934194e",
  devContract  : "cx465bb9d19aa59a6d9e4d492ee204bd2ff4f3af9d",
  stageContract: "cxdd929be1a52b96a057c298e7191e2cd59f766b91",
}) as HavahContractInfo

export const havahBridges = {
  // ARB_BRIDGE        : arbBridge,
  PER_BRIDGE        : perBridge,
  XPER_BRIDGE       : xperBridge,
  PERPLAY_NFT_BRIDGE: perplayNftBridge,
} as const


/** @TOKEN */
const hvhToken = defineContractInfo({
  ...defaultConfig,
  id           : "havah-hvh-token",
  name         : "HVH",
  symbol       : "HVH",
  contract     : "cxd7fed2ae1d9b2314087520efe1870c33e5c5baa4", // @TODO 수정필요
  devContract  : "cx55fe1cd2a2d36f2233d2cef077b1e06d2e7f233c",
  stageContract: "cx55fe1cd2a2d36f2233d2cef077b1e06d2e7f233c",
}) as HavahContractInfo

const arbToken = defineContractInfo({
  ...defaultConfig,
  id           : "havah-arb-token",
  name         : "ARB",
  symbol       : "ARB",
  contract     : "cx7e0afbcc14c25caa1f8d3f14b42ecdbc90dea861",
  devContract  : "cx177cca11e1b79915edf2b514a7163c81c4d91ed3",
  stageContract: "cx177cca11e1b79915edf2b514a7163c81c4d91ed3",
}) as HavahContractInfo

const perToken = defineContractInfo({
  ...defaultConfig,
  id           : "havah-per-token",
  name         : "PER",
  symbol       : "PER",
  contract     : "cxd7fed2ae1d9b2314087520efe1870c33e5c5baa4",
  devContract  : "cx55fe1cd2a2d36f2233d2cef077b1e06d2e7f233c",
  stageContract: "cx55fe1cd2a2d36f2233d2cef077b1e06d2e7f233c",
}) as HavahContractInfo

const xperToken = defineContractInfo({
  ...defaultConfig,
  id           : "havah-xper-token",
  name         : "XPER",
  symbol       : "XPER",
  contract     : "cx845d39382f26509a4db7af8e3b38159f9c533852",
  devContract  : "cx6543c9d479c025fbde1d38e0bb7b2ca758053e15",
  stageContract: "cx6543c9d479c025fbde1d38e0bb7b2ca758053e15",
}) as HavahContractInfo


export const havahTokens = {
  // ARB_TOKEN : arbToken,
  PER_TOKEN : perToken,
  XPER_TOKEN: xperToken,
} as const


/** @NFT */
const perplayNft = defineContractInfo({
  ...defaultConfig,
  id           : "havah-perplay-nft",
  name         : "PERPLAY",
  symbol       : "VEHICLE",
  contract     : "cx71da4f9e8557a16e4c86833403109a96265ee4eb",
  devContract  : "cx37436f078297be0702c8482e820bbe71cd7fd4ff",
  stageContract: "cx37436f078297be0702c8482e820bbe71cd7fd4ff", // @TODO 수정필요
}) as HavahContractInfo

export const havahNftContract = {
  PERPLAY: perplayNft,
} as const

