import type {PopupStatus}        from "@resources/@types/common/constant";
import type {AssertionConstType} from "@resources/@types/common/type";
import type {
  AddressPayload,
  FungibleTokenTransferPayload,
  NftTransferPayload,
  QrCodePayload,
  SelectChainPayload,
  SelectNftPayload,
  SelectTokenPayload,
  SelectWalletTypePayload,
  SwapSlippagePayload,
  SwapTransferPayload
}                                from "@resources/@types/modal/modalPayload";

export const MODAL_TYPE = {
  /** @COMMON */
  QR_CODE           : 'QR_CODE',
  ADDRESS           : 'ADDRESS',
  SELECT_CHAIN      : 'SELECT_CHAIN',
  SELECT_WALLET_TYPE: 'SELECT_WALLET_TYPE',
  SELECT_TOKEN      : 'SELECT_TOKEN',
  /** @FUNGIBLE-TOKEN */
  FUNGIBLE_TOKEN_TRANSFER: 'FUNGIBLE_TOKEN_TRANSFER',
  /** @NFT */
  NFT_TRANSFER: 'NFT_TRANSFER',
  SELECT_NFT  : 'SELECT_NFT',
  /** @SWAP */
  SWAP_SLIPPAGE: 'SWAP_SLIPPAGE',
  SWAP_TRANSFER: 'SWAP_TRANSFER',
} as const;

export type ModalType = AssertionConstType<typeof MODAL_TYPE>

export const MODAL_TRANSFER_STEP = {
  INFO_CHECK  : 'infoCheck',
  TRANSFERRING: 'transferring',
  RESULT      : 'result',
} as const

export type ModalTransferStep = AssertionConstType<typeof MODAL_TRANSFER_STEP>

export const MODAL_TRANSFER_DIRECTION = {
  NONE: 'none',
  NEXT: 'next',
  PREV: 'prev',
} as const

export type ModalTransferDirection = AssertionConstType<typeof MODAL_TRANSFER_DIRECTION>

export type ModalEtcProps<T extends "default" | "inComponent"> = T extends "default"
  ? {
    useWindowAlert?: boolean;
    canClickOverlay?: boolean;
    timeout?: number | {
      enter: number;
      exit: number;
    }
  }
  : {
    useWindowAlert?: never;
    canClickOverlay?: never;
    timeout: {
      enter: number;
      exit: number;
    };
  }

interface CommonModalProp<T extends ModalType = ModalType> {
  type: T;
  hash: string;
  status?: PopupStatus;
}


export type Modal<T extends ModalType = ModalType> = CommonModalProp<T> & {
  payload: ModalPayloadByType<T>;
  etc?: ModalEtcProps<"default">;
}

export type GetModalComponentPropsType<T extends ModalType = ModalType> = Omit<CommonModalProp<T>, "status"> & {
  inProp: boolean;
  offModal: () => void;
  payload: ModalPayloadByType<T>
  etc: ModalEtcProps<"inComponent">;
}

export type ModalComponentPropsType<T extends ModalType = ModalType> = {
  inProp: boolean;
  offModal: () => void;
  payload: ModalPayloadByType<T>
  etc: ModalEtcProps<"inComponent">;
}


type ModalPayloadMap = {
  [MODAL_TYPE.QR_CODE]: QrCodePayload;
  [MODAL_TYPE.ADDRESS]: AddressPayload;
  [MODAL_TYPE.SELECT_CHAIN]: SelectChainPayload;
  [MODAL_TYPE.SELECT_WALLET_TYPE]: SelectWalletTypePayload;
  [MODAL_TYPE.SELECT_TOKEN]: SelectTokenPayload;
  [MODAL_TYPE.FUNGIBLE_TOKEN_TRANSFER]: FungibleTokenTransferPayload;
  [MODAL_TYPE.NFT_TRANSFER]: NftTransferPayload;
  [MODAL_TYPE.SELECT_NFT]: SelectNftPayload;
  [MODAL_TYPE.SWAP_SLIPPAGE]: SwapSlippagePayload;
  [MODAL_TYPE.SWAP_TRANSFER]: SwapTransferPayload;
};

export type ModalPayloadByType<T extends ModalType> = ModalPayloadMap[T];



