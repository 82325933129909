import type {
  AssertionConstType,
  HavahAddress
}                           from "@resources/@types/common/type";
import type {AxiosResponse} from "axios";

export const HAVAH_DATA_API_RET_CODE = {
  SUCCESS              : 0,
  NO_API_KEY_ID        : 1000,
  INVALID_API_KEY      : 1001,
  AUTHENTICATION_FAILED: 1002,
  UNAUTHORIZED         : 1003,
  INVALID_PARAMETER    : 1101,
  EXTERNAL_SYSTEM_ERROR: 1102,
  NETWORK_ERROR        : 1103,
  UNDEFINED_ERROR      : 9999,
  NOT_FOUND_CONTRACT   : 2000,
} as const


export type HavahDataApiResponseDataType<T> = {
  result: T;
  retCode: AssertionConstType<typeof HAVAH_DATA_API_RET_CODE>;
  retMessage: string;
}

export type HavahDataApiResponse<T> = AxiosResponse<HavahDataApiResponseDataType<T>>

/** @NFT-LIST */
export const HAVAH_NFT_STANDARD = {
  HSP_721 : 1,
  HSP_1155: 2,
} as const

export const NFT_LIST_PARAMETERS_ORDER = {
  NAME    : 1,
  ID      : 2,
  TIME    : 3,
  QUANTITY: 4,
} as const

export type HavahNft = {
  tokenId: number;
  name: string;
  description: string;
  imageUri: string;
  amount: number; // HSP721인 경우 무조건 1개, HSP1155인 경우 소유한 토큰 개수
  tokenUri: string;
}

export type HavahNftList = {
  total: number;
  cursor: string; // (O) 답변의 nfts 개수가 0개이면 제공되지 않음.
  nftType: AssertionConstType<typeof HAVAH_NFT_STANDARD>; // 1: HSP721, 2: HSP1155
  nfts: HavahNft[]
}

export type HavahNftListResponse = HavahNftList

export type HavahNftListParams = {
  // (M) Owner EvmAddress
  address: HavahAddress;
  // (M) Collection (contract) EvmAddress
  contractAddress: HavahAddress;
  // (O) Paging용 cursor. 없으면 첫 정렬 순서상 초기 목록 제공
  cursor?: string;
  // (O) Cursor 이후의 최대 요청 개수, default: 10
  limit?: number;
  // (O) 1: NFT 이름순, 2: token id 순, 3: NFT 소유권 획득 시간순, 4: 소유한 토큰 개수순 (HSP1155의 경우에만 의미가 있음), default: 2
  order?: AssertionConstType<typeof NFT_LIST_PARAMETERS_ORDER>;
}

export type HavahNftListQueryResponse = HavahNftList

export type HavahNftListQueryParams = Omit<HavahNftListParams, "address" | "contractAddress"> & {
  address?: HavahAddress;
}
