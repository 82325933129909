import {Buffer} from 'buffer'
// @ts-ignore
import process  from 'process'

// @ts-ignore
window.global  = window
// @ts-ignore
window.process = process
// @ts-ignore
window.Buffer  = Buffer

// @ts-ignore
BigInt.prototype['toJSON'] = function () {
  return this.toString()
}
