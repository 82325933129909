import {defineWalletConnector} from "@resources/config/wallet-connectors/config";
import {getImageUrl}           from "@utils/Utils";


const metaMask = defineWalletConnector({
  id      : "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  name    : "MetaMask",
  provider: (window) => window?.ethereum,
  icon    : getImageUrl("src/assets/images/logo/wallet/meta-mask.svg"),
})


const trustWallet = defineWalletConnector({
  id      : "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
  name    : "Trust Wallet",
  provider: "isTrustWallet",
  icon    : getImageUrl("src/assets/images/logo/wallet/trust-wallet.svg"),
})


const rainbow = defineWalletConnector({
  id      : "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369",
  name    : "Rainbow",
  provider: "isRainbow",
  icon    : getImageUrl("src/assets/images/logo/wallet/rainbow.svg"),
})


const uniSwap = defineWalletConnector({
  id      : "c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a",
  name    : "Uniswap",
  provider: "isRainbow",
  icon    : getImageUrl("src/assets/images/logo/wallet/uni-swap.svg"),
})


export {
  metaMask,
  trustWallet,
  rainbow,
  uniSwap,
}
