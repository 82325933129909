import type {InjectedParameters} from "@wagmi/connectors";
import type {EIP1193Provider}    from "viem";
import {z}                       from "zod";

type WalletProviderFlags =
  | 'isApexWallet'
  | 'isAvalanche'
  | 'isBackpack'
  | 'isBifrost'
  | 'isBitKeep'
  | 'isBitski'
  | 'isBlockWallet'
  | 'isBraveWallet'
  | 'isCoinbaseWallet'
  | 'isDawn'
  | 'isEnkrypt'
  | 'isExodus'
  | 'isFrame'
  | 'isFrontier'
  | 'isGamestop'
  | 'isHyperPay'
  | 'isImToken'
  | 'isKuCoinWallet'
  | 'isMathWallet'
  | 'isMetaMask'
  | 'isOkxWallet'
  | 'isOKExWallet'
  | 'isOneInchAndroidWallet'
  | 'isOneInchIOSWallet'
  | 'isOpera'
  | 'isPhantom'
  | 'isPortal'
  | 'isRabby'
  | 'isRainbow'
  | 'isStatus'
  | 'isTally'
  | 'isTokenPocket'
  | 'isTokenary'
  | 'isTrust'
  | 'isTrustWallet'
  | 'isXDEFI'
  | 'isZerion'


type ConnectorProvider =
  | WalletProviderFlags
  | WalletProvider
  | ((window?: Window | undefined) => WalletProvider | undefined);

type Compute<type> = { [key in keyof type]: type[key] } & unknown
type WalletProvider = Compute<
  EIP1193Provider & {
  [key in WalletProviderFlags]?: true | undefined
} & {
    providers?: WalletProvider[] | undefined
    /** Only exists in MetaMask as of 2022/04/03 */
    _events?: { connect?: (() => void) | undefined } | undefined
    /** Only exists in MetaMask as of 2022/04/03 */
    _state?:
      | {
          accounts?: string[]
          initialized?: boolean
          isConnected?: boolean
          isPermanentlyDisconnected?: boolean
          isUnlocked?: boolean
        }
      | undefined
  }
>

const DefineWalletConnector = z.object({
  id            : z.string(),
  name          : z.string(),
  icon          : z.string(),
  provider      : z.custom<ConnectorProvider>(),
  shimDisconnect: z.boolean().optional(),
})
type DefineWalletConnector = z.infer<typeof DefineWalletConnector>

const WalletConnector = z.object({
  id            : z.string(),
  icon          : z.string(),
  injectedParams: z.custom<InjectedParameters>(),
})
type WalletConnector = z.infer<typeof WalletConnector>


export const defineWalletConnector = (config: DefineWalletConnector): WalletConnector => {

  const {
          id,
          name,
          provider,
          icon,
          shimDisconnect,
          ...rest
        } = config



  return {
    id            : id,
    icon          : icon,
    injectedParams: {
      shimDisconnect: shimDisconnect,
      target        : {
        id      : id,
        name    : name,
        provider: provider,
        icon    : icon,
      }
    } as InjectedParameters,
  }
}
