import {POPUP_STATUS} from "@resources/@types/common/constant";
import {removeSpace}  from "@utils/Utils";
import {
  type Draft,
  produce,
}                     from 'immer';
import {create}       from "zustand";
import {immer}        from 'zustand/middleware/immer';
import type {
  ModalStore,
  ModalStoreInitialState
}                     from "./schema";


const initialState: ModalStoreInitialState = {
  modal: {
    list            : [],
    searchBarKeyword: "",
  },
};

const useModalStore = create<ModalStore>()(
  immer((set, get) => ({
    ...initialState,
    actions: {
      getModalStatus        : (targetHash) => (
        get().modal.list.filter(({hash}) => hash === targetHash)[0]?.status
      ),
      createModal           : ({hash: targetHash, ...args}) => {
        set(produce((draft: Draft<ModalStoreInitialState>) => {
          if (draft.modal.list.some(({hash}) => hash === targetHash)) {
            draft.modal.list = draft.modal.list.map((_modal) => _modal.hash === targetHash ? {..._modal, payload: args.payload, status: POPUP_STATUS.ON} : _modal);
          } else {
            draft.modal.list.push({
              ...args,
              status: POPUP_STATUS.ON,
              hash  : targetHash,
            });
          }
        }));
      },
      updateModalStatusOn   : (targetHash) => {
        set(produce((draft: Draft<ModalStoreInitialState>) => {

          const _modal = draft.modal.list.find(({hash}) => hash === targetHash);

          if (!!_modal) _modal.status = POPUP_STATUS.ON;
        }));
      },
      updateModalStatusOff  : (targetHash) => {
        set(produce((draft: Draft<ModalStoreInitialState>) => {

          const _modal = draft.modal.list.find(({hash}) => hash === targetHash);

          if (!!_modal) _modal.status = POPUP_STATUS.OFF;
        }));
      },
      updateModalPayload    : (targetHash, payload) => {
        set(produce((draft: Draft<ModalStoreInitialState>) => {
          draft.modal.list = draft.modal.list.map((_modal) => (
            _modal.hash === targetHash ? {..._modal, payload: payload} : _modal
          ));
        }));
      },
      popModalList          : () => {
        set(produce((draft: Draft<ModalStoreInitialState>) => {
          draft.modal.list.pop();
        }));
      },
      resetModalList        : () => {
        set(produce((draft: Draft<ModalStoreInitialState>) => {
          draft.modal.list = initialState.modal.list;
        }));
      },
      updateSearchBarKeyword: (keyword) => {
        set(produce((draft: Draft<ModalStoreInitialState>) => {
          draft.modal.searchBarKeyword = keyword;
        }));
      },
      resetSearchBarKeyword : () => {
        set(produce((draft: Draft<ModalStoreInitialState>) => {
          draft.modal.searchBarKeyword = initialState.modal.searchBarKeyword;
        }));
      },
    },
  })));

/**
 * @STATE
 */
export const useModalList                              = () => useModalStore(state => ({modalList: state.modal.list}));
export const useModalStatus                            = () => (targetHash: string) => useModalStore(state => ({
  status: (state.modal.list.filter(({hash}) => hash === targetHash)[0])?.status
}));
export const useModalGetOnLength                       = () => useModalStore(state => ({
  onLength: (state.modal.list.filter(({status}) => status === POPUP_STATUS.ON)).length
}));
export const useModalSearchBarKeyword                  = () => useModalStore(state => ({keyword: state.modal.searchBarKeyword}));
export const useModalRemovedSpaceLowerSearchBarKeyword = () => useModalStore(state => ({removedSpaceLowerKeyword: removeSpace(state.modal.searchBarKeyword).toLowerCase()}));
/**
 * @ACTIONS
 */
export const useModalActions                           = () => useModalStore(state => state.actions);
