import {
  ModalContainer,
  ToastContainer,
  WagmiReactQueryContainer
}                           from "@components/container";
import Router               from "@router/Router";
import {QueryClient}        from "@tanstack/react-query";
import {createRoot}         from 'react-dom/client'
import {RouterProvider}     from "react-router-dom";
import '@assets/css/base/_index.scss'


const qc = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount      : true,
      refetchOnReconnect  : true,
      refetchOnWindowFocus: false,
      networkMode         : "online",
    }
  },
})


const root = document.getElementById('root')

createRoot(root!).render(
  <WagmiReactQueryContainer qc={qc}>
    {/*<ReactQueryDevtools initialIsOpen={false} position={"left"} buttonPosition={"bottom-left"}/>*/}
    <RouterProvider router={Router({qc})}/>
    <ModalContainer/>
    <ToastContainer/>
  </WagmiReactQueryContainer>
)


