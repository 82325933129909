import {HavahAddress}      from "@resources/@types/common/type";
import {TOAST_TYPE}        from "@resources/@types/toast/toast";
import {havah}             from "@resources/config/contracts";
import {havahQueryOptions} from "@services/havah/quries";
import {
  useHavahAccountsQuery,
  useHavahConnectMutation,
  useHavahConnectQuery
}                          from "@services/havah/useHavahService";
import {useQueryClient}    from "@tanstack/react-query";
import {splittingAddress}  from "@utils/Format";
import {setTimer}          from "@utils/Utils";
import {
  useCallback,
  useEffect,
  useState
}                          from "react";
import {
  useDidMountEffect,
  useToastControl
}                          from "./index";


const useHavahAccount = () => {
  const qc                                   = useQueryClient()
  const {onToast: onWalletInstallErrorToast} = useToastControl()
  const {onToast: onNetworkSyncErrorToast}   = useToastControl()
  const {onToast: onConnectErrorToast}       = useToastControl()

  const {mutateAsync: connectMutateAsync} = useHavahConnectMutation()
  const {data: connectData}               = useHavahConnectQuery()
  const {data: accountData}               = useHavahAccountsQuery()

  const [address, setAddress]           = useState<HavahAddress>()
  const [splitAddress, setSplitAddress] = useState<HavahAddress>()
  const [hasAddress, setHasAddress]     = useState(false)
  const [nid, setNid]                   = useState<string>()



  const _setAddresses = useCallback((data?: {
    address: HavahAddress;
    nid: string;
  }) => {
    if (!data) return;
    const {nid, address} = data

    const {success, data: _parsedAddress, error} = HavahAddress.safeParse(address)

    if (!success || !_parsedAddress || error) {
      setNid("0")
      setAddress("hx")
      setSplitAddress("hx")
      setHasAddress(false)

      return;
    }
    setNid(nid)
    setAddress(_parsedAddress)

    const _splitAddress = splittingAddress(_parsedAddress) as HavahAddress

    setHasAddress(!!_splitAddress)
    setSplitAddress(_splitAddress)
  }, [accountData])




  const open = async () => {
    if (!window.havah) {
      onWalletInstallErrorToast({
        type   : TOAST_TYPE.WARNING,
        payload: {
          title: "Wallet Not Found",
          msg  : "Please check your browser extension has HAVAH Wallet.",
        },
      })
      return false;
    } else {
      const res = await connectMutateAsync()

      if (!res) return false;

      const {
              ok,
              body,
            }     = res
      const {nid} = body

      const _isNetworkSync = Number(nid) === havah.id

      if (!ok) {
        onConnectErrorToast({
          type   : TOAST_TYPE.ERROR,
          payload: {
            title: "Connect Failed",
            msg  : "HAVAH Wallet Connect has Error",
          }
        })
        return false;
      } else if (!_isNetworkSync) {
        onNetworkSyncErrorToast({
          type   : TOAST_TYPE.WARNING,
          payload: {
            title: "HAVAH Network Mismatch Detected",
            msg  : "Please check your HAVAH network settings.",
          }
        })
        return false;
      }

      return true;
    }
  }

  const disconnect = useCallback(async () => {
    await new Promise((resolve) => resolve(qc.setQueryData(havahQueryOptions.connect().queryKey, {
      isNetworkSync: false,
      isConnected  : false,
    })))

    await qc.resetQueries({
      queryKey: havahQueryOptions.accounts().queryKey,
    })

    _setAddresses()
  }, [qc, connectData, accountData])


  useEffect(() => {
    _setAddresses(accountData)
  }, [_setAddresses]);

  useDidMountEffect(() => {
    setTimer(() => {
      disconnect()
    }, 300)
  }, [location.pathname])



  return {
    isConnected : !!connectData && connectData.isConnected && connectData.isNetworkSync && !!accountData && !!accountData.address,
    address     : address,
    splitAddress: splitAddress,
    hasAddress  : hasAddress,
    nid         : nid,
    disconnect  : disconnect,
    open        : open,
  }
}

export default useHavahAccount;
